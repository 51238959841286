import React, { useState, useEffect } from 'react';
import Axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { hashedApiKey } from '../../api';
import { API_BASE_URL } from '../../api';
import Dashboard from '../Dashboard/Dashboard';

export default function SignIn({setIsAuthenticated,isAuthenticated,setIsAdmin}) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        if(isAuthenticated){
            navigate('/dashboard');
        }
      }, []);

    const handleSubmit = event => {
        event.preventDefault()
        Axios.post(API_BASE_URL + '/api/SignIn',
            { email: email, password: password },
            { headers: { 'x-api-key': hashedApiKey } }
        ).then((response) => {
            if (response.status === 200) {
                const IsAdmin = response.data.IsAdmin;
                if (IsAdmin === 1){
                    localStorage.setItem('admin', JSON.stringify(true));
                    setIsAdmin(true)
                    navigate('/');
                }else{
                    const brandId = response.data.brandId;
                    localStorage.setItem('brand', JSON.stringify(brandId));
                    setIsAuthenticated(true)
                    localStorage.setItem('IsAuthenticated', JSON.stringify(true));
                    navigate('/dashboard');
                }
                
            } else {
                localStorage.setItem('IsAuthenticated', JSON.stringify(false));
                alert('Invalid credentials');
            }
        }).catch(error => {
            console.error('Error signing in:', error);
            localStorage.setItem('IsAuthenticated', JSON.stringify(false));
            alert('An error occurred during sign in');
        });
    }

    return (
        <main class="d-flex w-100">
            <div class="container d-flex flex-column">
                <div class="row vh-100">
                    <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div class="d-table-cell align-middle">

                            <div class="text-center mt-4">
                                <h1 class="h2">Welcome To Senteez</h1>
                                <p class="lead">
                                    Sign in to your account to continue
                                </p>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="m-sm-4">
                                        <div class="text-center mb-3">
                                            <strong className='text-danger'>Request a Demo: Email us at contact@senteez.com</strong>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div class="mb-3">
                                                <label class="form-label">Email</label>
                                                <input class="form-control form-control-lg" type="email" name="email"
                                                    value={email}
                                                    onChange={event => setEmail(event.target.value)}
                                                    placeholder="Enter your email" />
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Password</label>
                                                <input class="form-control form-control-lg" type="password" name="password"
                                                    value={password}
                                                    onChange={event => setPassword(event.target.value)}
                                                    placeholder="Enter your password" />
                                                <small>
                                                    <a href="">Forgot password?</a>
                                                </small>
                                            </div>
                                            <div>
                                                <label class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="remember-me"
                                                        name="remember-me" checked />
                                                    <span class="form-check-label">
                                                        Remember me next time
                                                    </span>
                                                </label>
                                            </div>
                                            <div class="text-center mt-3">
                                                <button type='submit' class="btn btn-lg btn-primary">Sign in</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
