import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom';
import style from './Sidebar.module.css'
import { hashedApiKey } from '../../api';
import { API_BASE_URL } from '../../api';

const Sidebar = ({ brand_id, setPage, setProductID, setFilter, isOpen }) => {
    const [productNameList, setProductNameList] = useState([])
    const [brandName, setBrandName] = useState([])

    useEffect(() => {
        Axios.get(API_BASE_URL+'/api/getProducts', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setProductNameList(response.data.map(record => ({ id: record.Product_ID, name: record.Product_Name })))
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL+'/api/getBrandName', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setBrandName(response.data)
        })
    }, [])

    return (
        <nav id={`sidebar`} className={`navbar-collapse ${isOpen ? 'show' : 'collapse'} ${style.sidebar}`}>
            <div class={`${style.sidebar_content}`} >
                <a class={style.sidebar_brand} href={'/dashboard'}>
                    {brandName.map((data) => (
                        data.Brand_Name
                    ))}
                </a>
                <ul class={style.sidebar_nav}>
                    <li class={style.sidebar_header} >
                        <a class={`${style.a_tags}`} onClick={() => { setPage('Brand') }}>My Dashboard</a>
                    </li>
                    {/* <li class={style.sidebar_header} >
                        <a class={`${style.a_tags}`} onClick={() => { setPage('OCES') }}>Analysis Factors</a>
                    </li> */}
                    {/* <li class={style.sidebar_header} >
                        <a class={`${style.a_tags}`} onClick={() => { setPage('reports'); setFilter({ product: '*', factor: 'Recommendations', score: '*' }) }}>Recommandations</a>
                    </li>
                    <li class={style.sidebar_header} >
                        <a class={`${style.a_tags}`} onClick={() => { setPage('reports'); setFilter({ product: '*', factor: '*', score: '5' }) }}>Top Reviews</a>
                    </li> */}
                    <li class={style.sidebar_header} >
                        <a class={`${style.a_tags} collapsed`} data-toggle="collapse" href="#dashboard" aria-expanded="false">Branches</a>
                    </li>
                    <div class="collapse" id="dashboard">
                        <ul class="nav nav-collapse">
                            {
                                productNameList.map(productName => (
                                    <li class={`${style.sidebar_item} ${style.li}`}>
                                        <Link to={`/dashboard`} onClick={() => { setPage('Product'); setProductID(productName.id) }} class={style.sidebar_link}>
                                            <i class="align-middle" data-feather="sliders"></i> <span
                                                class="align-middle">{productName.name}</span>
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </ul>
                <div class={style.copywrite}>
                    <button className='btn btn-sm btn-primary' onClick={() => window.open('https://senteez.com', '_blank')}>&copy; Senteez.com {new Date().getFullYear()}</button>
                    <div>
                        <a class={`${style.a_tagsp}`} href="https://www.linkedin.com/company/senteez/" target="_blank">LinkedIn</a> | <a class={`${style.a_tagsp}`} href="https://www.instagram.com/senteezanalysis/" target="_blank">Instagram</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Sidebar;

