import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    plugins
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    plugins,
    ChartDataLabels
);


export default function ReactCompatitorChart({ productData, productCompetitorData }) {

    const chartData = {
        labels: ['Overall','Ingredients','Skin Compatibility','Moisturizing','Scent'],//['Overall', 'Content', 'Engagement', 'Practice', 'Career Benefit'],
        
        datasets: [
            {
                label: productData.productName,
                backgroundColor: '#037ef3',
                data: [productData.productOverall, productData.ProductIngredients, productData.ProductSkinCompatibility, productData.ProductScent, productData.ProductMoisturizing],
                countData: [productData.productOverallTotal, productData.ProductIngredientsTotal, productData.ProductSkinCompatibilityTotal, productData.ProductScentTotal, productData.ProductMoisturizingTotal],
                datalabels: {
                    display: true,
                    anchor: 'center',
                    align: 'center',
                    color: '#ffff',
                    font: {
                        size: 14,
                        weight: 'bold'
                    },
                    formatter: (value, context) => {
                        const count = context.dataset.countData[context.dataIndex];
                        const countDisplay = count >= 1000 ? `${(count / 1000).toFixed(1)}K` : count;
                        return ` ${value}% \n(${countDisplay})`
                    }
                }
            },
            {
                label: productCompetitorData.CompetitorName,
                backgroundColor: '#ffc845',
                data: [productCompetitorData.CompetitorOverall, productCompetitorData.CompetitorIngredients, productCompetitorData.CompetitorSkinCompatibility, productCompetitorData.CompetitorScent, productCompetitorData.CompetitorMoisturizing],
                countData: [productCompetitorData.CompetitorOverallTotal, productCompetitorData.CompetitorIngredientsTotal, productCompetitorData.CompetitorSkinCompatibilityTotal, productCompetitorData.CompetitorScentTotal, productCompetitorData.CompetitorMoisturizingTotal],
                datalabels: {
                    display: true,
                    anchor: 'center',
                    align: 'center',
                    color: '#000',
                    font: {
                        size: 14,
                        weight: 'bold'
                    },
                    formatter: (value, context) => {
                        const count = context.dataset.countData[context.dataIndex];
                        const countDisplay = count >= 1000 ? `${(count / 1000).toFixed(1)}K` : count;
                        return ` ${value}% \n(${countDisplay})`
                    }
                }
            }
        ]
    };

    const options = {
        scales: {
            x: {
                ticks: {
                    color: 'black',
                }
            },
            y: {
                ticks: {
                    color: 'black',
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: 'black',
                    font: {
                        size: '12',
                    }
                }
            }
        }
    };

    return <Bar options={options} data={chartData} />;

}
