import './App.css';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import BrandList from './Components/BrandList';
import Dashboard from './Components/Dashboard/Dashboard';
import SignIn from './Components/InstituteSignIn/SignIn';
import DataEntry from './Components/DataEntry';
import RetailReviewsCleaning from './Components/RetailReviewsCleaning';
import ProtectedRoute from './ProtectedRoute';
import ProductDetailInsertion from './Components/ProductDetailInsertion';
import ProtectedAdminRoute from './ProtectedAdminRoute';
import ReviewClassificationCleaning from './Components/ReviewClassificationCleaning';
import BrandRankingPage from './Components/BrandRankingPage'


function App() {
  const [isAdmin, setIsAdmin] = useState(
    localStorage.getItem('admin') === 'true'
  );
  
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    localStorage.setItem('admin', isAdmin);
  }, [isAdmin]);

  Axios.defaults.withCredentials = true;
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={<ProtectedAdminRoute component={BrandList} isAdmin={isAdmin} setIsAdmin={setIsAdmin} />}
          />
          <Route path="/SignIn" element={<SignIn isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setIsAdmin={setIsAdmin} />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={Dashboard} isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/DataEntry"
            element={<ProtectedAdminRoute component={DataEntry} isAdmin={isAdmin} />}
          />
          <Route path="/productdetail/:Brand_ID" element={<ProductDetailInsertion />} />
          <Route
            path="/DataCleaning"
            element={<ProtectedAdminRoute component={RetailReviewsCleaning} isAdmin={isAdmin} />}
          />
          <Route
            path="/ClassifiedDataCleaning"
            element={<ProtectedAdminRoute component={ReviewClassificationCleaning} isAdmin={isAdmin} />}
          />
          <Route
            path="/RankingPage"
            element={<ProtectedAdminRoute component={BrandRankingPage} isAdmin={isAdmin} />}
          />
          {/* <ProtectedRoute
            path="/dashboard"
            element={<Dashboard />}
            isAuthenticated={isAuthenticated}
          /> */}
          {/* <Route path="/" element={<BrandList />} /> */}
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/DataEntry" element={<DataEntry />} /> */}
          {/* <Route path="/DataCleaning" element={<RetailReviewsCleaning />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;

{/* <Instructor /> */ }
{/* <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
            </Route> */}
{/* <Route path="/Brand" element={<Brand />} /> */ }