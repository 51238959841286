import React from 'react'
import Hstyle from './Header.module.css'
import style from '../InstructorContent.module.css'

export default function Header({ name, value, icon }) {
    return (
        <div class={`${style.card} flex-fill w-100`}>
            <div className="card-body">
                <div class="row">
                    <div class="col mt-0">
                        <h5 class={style.card_title}>{name}</h5>
                    </div>
                    <div class="col-auto">
                        <div class={`text-primary ${Hstyle.stat}`}>
                            <i class={icon} aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <h1 className={`${Hstyle.h1} mt-1`}>{value}</h1>
            </div>
        </div>

    )
}
