import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Axios from 'axios'

import ReactBarChart from '../ReactBarChart'
import Header from '../DashboardInstructorContent/InstructorHeaders/Header';
import ReactStackedBarChart from '../ReactStackedBarChart'
import ReactPieChart from '../ReactPieChart';
import ReactDonetChart from '../ReactDonetChart';
import ReactMultiLineChart from '../ReactMultiLineChart'
import ReactCompatitorChart from '../ReactCompatitorChart';
import ReactHorizontalBarChart from '../ReactHorizontalBarChart';

import style from '../DashboardInstructorContent/InstructorContent.module.css'
import { hashedApiKey } from '../../api';
import { API_BASE_URL } from '../../api'



export default function CourseContent({ productID, setPage, setFilter }) {
    const [courseSummaryByFactors, setCourseSummaryByFactors] = useState([])
    const [productSummary, setProductSummary] = useState([])
    const [productFactorsDetail, setProductFactorsDetail] = useState({})
    const [courseStarCount, setCourseStarCount] = useState([])
    const [productHeaderData, setProductHeaderData] = useState([])
    const [donutTotal, setDonutTotal] = useState([])
    const [donutPositive, setDonutPositive] = useState([])
    const [donutName, setDonutName] = useState([])
    const [productTopReviews, setProductTopReviews] = useState([])
    const [productTopRecommendations, setProductTopRecommendations] = useState([])
    const [productName, setProductName] = useState([])
    const [lineChartData, setLineChartData] = useState({})
    const [productRecurringThemes, setProductRecurringThemes] = useState([]);
    const [chartData, setChartData] = useState({ labels: [], counts: [] });
    const [productSummaryInsight, setProductSummaryInsight] = useState([])
    const [productInsights, setProductInsights] = useState({
        insight_1_result: 0,
        insight_1_grade: false,
        insight_2_result: 0,
        insight_2_grade: false
    });

    const [productData, setProductData] = useState({
        InstructorOverall: 0,
        InstructorIngredients: 0,
        InstructorSkinCompatibility: 0,
        InstructorMoisturizing: 0,
        InstructorScent: 0
    });

    const [productCompetitorData, setProductCompetitorData] = useState({
        CompetitorOverall: 0,
        CompetitorIngredients: 0,
        CompetitorSkinCompatibility: 0,
        CompetitorMoisturizing: 0,
        CompetitorScent: 0,
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        let compatitorPositive = 0
        let productPositive = 0

        Axios.get(API_BASE_URL + '/api/getProductSummary', { params: { productID: productID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            let starCount = response.data.map(record => record.Star_Count)[0]
            starCount = starCount.split(/[,\s]/).map(Number)
            setCourseStarCount(starCount)
            setProductSummary(response.data)
            productPositive = response.data[0].Positive
            const productOverall = Math.round((response.data[0].Positive / response.data[0].Total) * 100)
            const productOverallTotal = response.data[0].Total
            setProductData(prevState => ({ ...prevState, productOverall, productOverallTotal }));
        });

        //     // ------------------------compatitor data ----------------------
        Axios.get(API_BASE_URL + '/api/getProductCompetitorSummary', { params: { productID: productID }, headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                const CompetitorName = response.data[0].Product_Name
                const CompetitorOverall = Math.round((response.data[0].Positive / response.data[0].Total) * 100);
                const CompetitorOverallTotal = response.data[0].Total
                // compatitorPositive = response.data[0].Positive
                setProductCompetitorData(prevState => ({ ...prevState, CompetitorOverall, CompetitorOverallTotal, CompetitorName }));
                compatitorPositive = response.data[0].Positive
                // Percentage difference = ((80 - 100) / 100) * 100%
                let percentage = ((productPositive - compatitorPositive) / compatitorPositive) * 100
                let insight_3_result = percentage.toFixed(1)
                setProductInsights(prevState => ({ ...prevState, insight_3_result }))
            });

        Axios.get(API_BASE_URL + '/api/getProductCompetitorSummaryByFactors', { params: { productID: productID }, headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                const CompetitorIngredients = Math.round((response.data[0].Positive / response.data[0].Total) * 100);
                const CompetitorIngredientsTotal = response.data[0].Total
                const CompetitorSkinCompatibility = Math.round((response.data[1].Positive / response.data[1].Total) * 100);
                const CompetitorSkinCompatibilityTotal = response.data[1].Total
                const CompetitorMoisturizing = Math.round((response.data[2].Positive / response.data[2].Total) * 100);
                const CompetitorMoisturizingTotal = response.data[2].Total
                const CompetitorScent = Math.round((response.data[3].Positive / response.data[3].Total) * 100);
                const CompetitorScentTotal = response.data[3].Total

                setProductCompetitorData(prevState => ({ ...prevState, CompetitorIngredients, CompetitorIngredientsTotal, CompetitorMoisturizing, CompetitorMoisturizingTotal, CompetitorSkinCompatibility, CompetitorSkinCompatibilityTotal, CompetitorScent, CompetitorScentTotal }));
            });
    }, [productID])


    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getProductRecurringThemes', { params: { productID: productID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            const sortedData = response.data.sort((a, b) => b.Total - a.Total);
            setProductRecurringThemes(sortedData);

            // Extracting Total and Factor_Name for the horizontal bar chart
            const labels = sortedData.map(item => item.Factor_Name);
            const counts = sortedData.map(item => item.Total);
            setChartData({ labels, counts });
        })
    }, [productID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getProductInsights', { params: { productID: productID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setProductSummaryInsight(JSON.parse(response.data[0].key_themes))
        })
    }, [productID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getProductSummaryByFactors',
            {
                params: { productID: productID }, headers: { 'x-api-key': hashedApiKey }
            }).then((response) => {
                const factorsdetail = {
                    'positive': response.data.map(record => record.Positive),
                    'negative': response.data.map(record => record.Negative),
                    'neutral': response.data.map(record => record.Neutral)
                }

                setDonutName(response.data.map(record => record.Factor_Name).slice(0, -1))
                setDonutPositive(response.data.map(record => record.Positive).slice(0, -1))
                setDonutTotal(response.data.map(record => record.Total).slice(0, -1))
                setProductFactorsDetail(factorsdetail)
                setCourseSummaryByFactors(response.data)

                const ProductIngredients = Math.round((response.data[0].Positive / response.data[0].Total) * 100);
                const ProductIngredientsTotal = response.data[0].Total
                const ProductSkinCompatibility = Math.round((response.data[1].Positive / response.data[1].Total) * 100);
                const ProductSkinCompatibilityTotal = response.data[1].Total
                const ProductMoisturizing = Math.round((response.data[2].Positive / response.data[2].Total) * 100);
                const ProductMoisturizingTotal = response.data[2].Total
                const ProductScent = Math.round((response.data[3].Positive / response.data[3].Total) * 100);
                const ProductScentTotal = response.data[3].Total

                setProductData(prevState => ({ ...prevState, ProductIngredients, ProductIngredientsTotal, ProductMoisturizing, ProductMoisturizingTotal, ProductSkinCompatibility, ProductSkinCompatibilityTotal, ProductScent, ProductScentTotal }));
            })
    }, [productID])



    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getProductName', { params: { productID: productID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setProductName(response.data)
            const productName = response.data[0].Product_Name
            setProductData(prevState => ({ ...prevState, productName }));
        })
    }, [productID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getProductHeaderData', { params: { productID: productID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setProductHeaderData(response.data)
        })
    }, [productID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getProductLineChart', { params: { productID: productID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            const data = response.data;
            const reviewMonths = data.map(item => item.review_month).slice(0, -1).slice(-12);
            const formattedDates = reviewMonths.map(dateStr => {
                const [year, month] = dateStr.split('-');
                const monthName = new Date(`${dateStr}-01`).toLocaleString('default', { month: 'short' });
                return `${monthName} ${year}`;
            });
            const positiveReviews = data.map(item => item.positive_reviews).slice(0, -1).slice(-12);
            const negativeReviews = data.map(item => item.negative_reviews).slice(0, -1).slice(-12);
            const neutralReviews = data.map(item => item.neutral_reviews).slice(0, -1).slice(-12);

            const LinechartData = {
                formattedDates,
                positiveReviews,
                negativeReviews,
                neutralReviews
            };
            setLineChartData(LinechartData)


            // -------------------- insight no 1 ----------------------
            let previous_3_months_sum = LinechartData.positiveReviews.slice(-6)[0] + LinechartData.positiveReviews.slice(-6)[1] + LinechartData.positiveReviews.slice(-6)[2]
            let last_3_months_sum = LinechartData.positiveReviews.slice(-6)[3] + LinechartData.positiveReviews.slice(-6)[4] + LinechartData.positiveReviews.slice(-6)[5]
            let absoluteDiff = Math.abs(last_3_months_sum - previous_3_months_sum);
            let avg = (last_3_months_sum + previous_3_months_sum) / 2;
            let percentage = (absoluteDiff / avg) * 100;
            let insight_1_result = percentage.toFixed(1);
            let insight_1_grade = false

            if (last_3_months_sum > previous_3_months_sum) {
                insight_1_grade = true
            } else {
                insight_1_grade = false
            }

            // -------------------- insight no 2 ----------------------
            const previous_1_months_sum = LinechartData.positiveReviews.slice(-2)[0] + LinechartData.negativeReviews.slice(-2)[0] + LinechartData.neutralReviews.slice(-2)[0]
            const last_1_months_sum = LinechartData.positiveReviews.slice(-2)[1] + LinechartData.negativeReviews.slice(-2)[1] + LinechartData.neutralReviews.slice(-2)[1]
            absoluteDiff = Math.abs(last_1_months_sum - previous_1_months_sum);
            avg = (last_1_months_sum + previous_1_months_sum) / 2;
            percentage = (absoluteDiff / avg) * 100;
            let insight_2_result = percentage.toFixed(1);
            let insight_2_grade = false
            if (last_1_months_sum > previous_1_months_sum) {
                insight_2_grade = true
            } else {
                insight_2_grade = false
            }

            setProductInsights(prevState => ({ ...prevState, insight_1_result, insight_1_grade, insight_2_result, insight_2_grade }))
        })
    }, [productID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getProductTopReviews', { params: { productID: productID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setProductTopReviews(response.data)
        })
    }, [productID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getProductTopRecommendations', { params: { productID: productID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setProductTopRecommendations(response.data)
        })
    }, [productID])

    const parseExperiences = (experiences) => {
        try {
            return experiences ? JSON.parse(experiences) : [];
        } catch (error) {
            console.error("Error parsing experiences:", error);
            return [];
        }
    };

    let counter = 0;
    return (
        <main class={style.content}>
            <div class="container p-0 mt-3">
                <h1 class="mb-3">
                    <strong>{productName.map((data) => (
                        data.Product_Name
                    ))}
                    </strong>
                </h1>

                <div class={`${style.card} flex-fill w-100`}>
                    <div class="card-body d-flex flex-column flex-lg-row">
                        <div className='col-md-12 col-lg-7 col-xl-8 mt-3 order-md-1'>
                            {
                                productSummary.map((data) => (
                                    <p class={`${style.text_muted} mb-3`}>This branch has an overall rating of {data.TTC_Score.toFixed(1)} out of 5.0 based on {data.Total} total reviews analyzed.</p>
                                ))
                            }
                            <h5 class={`${style.card_title}`}>Key Insights:</h5>
                            <ul>
                                {productSummaryInsight.map((insight, index) => (
                                    <li key={index} class={`${style.text_muted}`}>
                                        {insight}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='col-md-12 col-lg-5 col-xl-4 mt-2 order-md-2'>
                            <ReactPieChart StarCount={courseStarCount} />
                        </div>
                    </div>
                </div>

                <div className="row mb-3 mt-3" >
                    <div className='col-lg-12 col-xl-6'>
                        <div className='row d-flex'>
                            {productHeaderData.map((data) => (
                                <>
                                    <div className='col-6 mb-4'>
                                        <Header name={'Rating'} value={`${Number(data.TTC_Score.toFixed(1))} / 5.0`} icon={'fa-regular fa-star'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Total Reviews'} value={data.Total} icon={'fa-regular fa-face-smile'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Positive Reviews'} value={((data.Positive / data.Total) * 100).toFixed(0) + '%'} icon={'fa-regular fa-thumbs-up'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Negative Reviews'} value={data.Negative} icon={'fa-solid fa-check'} />
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`} style={{ display: 'inline-block' }}>Overall Score (1-5)</h5><p className='ml-1' style={{ display: 'inline-block', padding: 0, margin: 0, }}>based on sentiment analysis</p>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <ReactBarChart StarCount={courseStarCount} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* line chart */}
                <div className="row">
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-3 mb-3">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h4 class={`${style.card_title}`}>Track the Pulse: Sentiment Trends Over Time</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Stay ahead of the curve and monitor sentiment trends over time with our intuitive sentiment analysis graph.</p>
                                <ReactMultiLineChart lineChartData={lineChartData} />
                            </div>
                        </div>
                    </div>
                </div>


                {/* competitor */}
                {/* <div class="row">
                    <div class="col-12 d-flex">
                        <div className={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`}>Competitor Comparison Chart</h5>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Compare your positive sentiment with your top competitor to gain insight into areas of improvement and stay ahead of the competition.</p>
                                <ReactCompatitorChart
                                    // brand_id={brand_id}
                                    productData={productData}
                                    productCompetitorData={productCompetitorData}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}


                {/* OCSE */}
                {/* <div className="row mt-3">
                    <div class="col-12 col-xxl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`}>Product Analysis Factors</h5>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Analyzing Products Effectiveness: Breakdown of Sentiment by Key Factors</p>
                                <ReactStackedBarChart FactorsDetail={productFactorsDetail} />
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* recommandations */}
                {/* <div className="row">
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div className={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h4 class={`${style.card_title}`}>Top Reviews</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>See the reviews that have made the most impact on your overall rating.</p>
                                <table class={`${style.table} table table-hover my-0`}>
                                    <thead>
                                        <tr>
                                            <th class="text-center d-none d-sm-none d-md-table-cell">#</th>
                                            <th class="col-1 col-sm-1 d-sm-table-cell text-center">Review By</th>
                                            <th class="col-11 col-sm-10 col-md-7 text-center">Review</th>
                                            <th class="d-none d-sm-none d-md-table-cell text-center">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <div className="d-none">
                                            {counter = 0}
                                        </div>
                                        {
                                            productTopReviews.slice(0, 5).map((data) => (
                                                <tr>
                                                    <td class='text-center d-none d-sm-none d-md-table-cell'>{++counter}</td>
                                                    <td class="col-1 col-sm-1 d-sm-table-cell text-center">{data.Display_Name}</td>
                                                    <td class="col-11 col-sm-10 col-md-7">{data.Product_Review}</td>
                                                    <td class="d-none d-sm-none d-md-table-cell text-center">{new Date(data.Review_Created_At).toLocaleDateString()}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className='text-center mb-3'>
                                    <Link className="btn btn-primary btn-sm mt-3 mx-auto" to="" onClick={() => { setPage('reports'); setFilter({ product: productID, factor: '*', score: '5' }) }}>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="row">
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div className={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h4 class={`${style.card_title}`}>Top Issues: Insights to Elevate your product</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Discover the top issues and areas for improvement extracted from real customer’s feedback to enhance your product and elevate overall customer satisfaction.</p>
                                <table class={`${style.table} table table-hover my-0`}>
                                    <thead>
                                        <tr>
                                            <th class="text-center d-none d-sm-none d-md-table-cell">#</th>
                                            <th class="col-1 col-sm-1 d-sm-table-cell text-center">Review By</th>
                                            <th class="col-11 col-sm-10 col-md-7 text-center">Review</th>
                                            <th class="d-none d-sm-none d-md-table-cell text-center">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <div className="d-none">
                                            {counter = 0}
                                        </div>
                                        {

                                            productTopRecommendations.map((data) => (
                                                <tr>
                                                    <td class='text-center d-none d-sm-none d-md-table-cell'>{++counter}</td>
                                                    <td class="col-1 col-sm-1 d-sm-table-cell text-center">{data.Display_Name}</td>
                                                    <td class="col-11 col-sm-10 col-md-7">{data.Product_Review}</td>
                                                    <td class="d-none d-sm-none d-md-table-cell text-center">{new Date(data.Review_Created_At).toLocaleDateString()}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className='text-center mb-3'>
                                    <Link className="btn btn-primary btn-sm mt-3 mx-auto" to="" onClick={() => { setPage('reports'); setFilter({ product: productID, factor: 'Recommendations', score: '*' }) }}>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className={`row`}>
                    <div className="col-12 d-flex">
                        <div className={`${style.card} flex-fill w-100`}>
                            <div className={`${style.card_header}`}>
                                <h5 className={`${style.card_title}`}>Learner Feedback Analysis: Recurring Themes</h5>
                            </div>

                            {/* Insert the horizontal bar chart here */}
                            <div className="px-4">
                                <ReactHorizontalBarChart
                                    StarCount={chartData.counts}
                                    setPage={setPage}
                                    setFilter={setFilter}
                                    productID={productID}
                                    labels={chartData.labels}
                                />
                            </div>

                            <div className={`${style.card_body} mt-5 px-4`}>
                                <p className={`${style.text_muted} mb-2`}>The following are the recurring themes identified from the customer feedback.</p>
                                {productRecurringThemes.map((detail, index) => {
                                    const positiveExperiences = parseExperiences(detail.PositiveExperiences);
                                    const negativeExperiences = parseExperiences(detail.NegativeExperiences);

                                    return (
                                        <div key={index} className={`${style.progress_container}`}>
                                            <div className={`${style.progress_title}`} style={{ cursor: 'pointer' }}>{index + 1}. {detail.Factor_Name}</div> 
                                            {/* onClick={() => { setPage('reports'); setFilter({ product: productID, factor: detail.Factor_Name, score: '*' }); }} */}
                                            <div className={`${style.progress_reviews}`}>Reviews: {detail.Total}</div>
                                            <div className={`${style.progress_bar}`}>
                                                <div className={`${style.progress_bar_inner}`} style={{ width: `${(detail.Positive / detail.Total) * 100}%` }}></div>
                                                <div className={`${style.progress_percentage}`}>{((detail.Positive / detail.Total) * 100).toFixed(0)}%</div>
                                            </div>
                                            <div className={`${style.additional_text}`}>
                                                {positiveExperiences.length > 0 && (
                                                    <>
                                                        Positive Experience:
                                                        <ul>
                                                            {positiveExperiences.map((experience, index) => (
                                                                <li key={index}>{experience}</li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                                {negativeExperiences.length > 0 && (
                                                    <>
                                                        Negative Experience:
                                                        <ul>
                                                            {negativeExperiences.map((experience, index) => (
                                                                <li key={index}>{experience}</li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`row`}>
                    <div class="col-12 d-flex justify-content-center mb-3">
                        <div class="text-center">
                            <p class={`${style.text_muted}`}>
                                Follow Us on
                                <a href="https://www.linkedin.com/company/senteez/" target="_blank" class="mx-1">LinkedIn</a> |
                                <a href="https://www.instagram.com/senteezanalysis/" target="_blank" class="mx-1">Instagram</a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    )
}
