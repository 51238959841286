import React from 'react'
import style from './Navbar.module.css'

export default function Navbar({ setIsOpen, isOpen }) {
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        // Clear localStorage completely
        localStorage.clear();
        // Reload the page
        window.location.reload();
    };

    return (
        <nav class={`${style.navbar} navbar-expand navbar-light navbar-bg`}>
            <a href='https://senteez.com/' class={style.sidebar_brand} target="_blank">
                <span>SENTEEZ</span><span className={style.sub}> - Revolutionizing Customer Feedback</span>
            </a>
            <div className='row ml-auto'>
                <a className='btn btn-danger btn-sm mr-2' onClick={handleLogout}>Logout</a>
                <a class={style.sidebar_toggle} onClick={toggleSidebar}>
                    <i class={`${style.hamburger} align-self-center`}></i>
                </a>
            </div>
        </nav>
    )
}
