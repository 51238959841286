import React from 'react'
import { Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export default function ReactMultiLineChart({lineChartData}) {
    const options = {
        plugins: {
            legend: {
                labels: {
                    color: 'black',
                    font: {
                        size: '12',
                    }
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: 'black',
                }
            },
            y: {
                ticks: {
                    color: 'black',
                }
            }
        },
    }
    const data = {
        labels: lineChartData.formattedDates,
        datasets: [
            {
                label: "Positive",
                data: lineChartData.positiveReviews,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            },
            {
                label: "Neutral",
                data: lineChartData.neutralReviews,
                fill: false,
                borderColor: "#ffc845"
            },
            {
                label: "Negative",
                data: lineChartData.negativeReviews,
                fill: false,
                borderColor: "#FF0000"
            }
        ]
    };

    return <Line data={data} options={options}  />
}
