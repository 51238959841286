import React from 'react'
import style from './DashboardInstructorContent/InstructorContent.module.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

export default function ReactPieChart({ StarCount }) {
    let Positive = StarCount[3] + StarCount[4]
    let Neutral = StarCount[2]
    let Negative = StarCount[0] + StarCount[1]
    const data = {
        labels: ['Positive', 'Neutral', 'Negative'],
        datasets: [{
            label: 'Reviews',
            data: [Positive, Neutral, Negative],
            backgroundColor: [
                '#037ef3',
                '#ffc845',
                '#FF0000'
            ],
            borderColor: "black",
            borderWidth: 2,
        }]
    }

    const options = {
        responsive: true,
        cutout: 0,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    // fontColor: '',
                    // fontSize: 15,
                    usePointStyle: true,
                    padding: 20
                }
            },
            datalabels: false
        },

        maintainAspectRatio: false
    }

    return (
        <div className={`${style.pie_chart}`}>
            <Doughnut data={data} options={options} />
        </div>
    )
}
