import React, { useState } from "react";

const smoothScrollToTop = () => {
    const scrollInterval = setInterval(() => {
        const scrollTop = window.pageYOffset;
        if (scrollTop <= 0) {
            clearInterval(scrollInterval);
            return;
        }
        window.scrollBy(0, -100);
    }, 20);
};

const Pagination = ({ currentPage, setCurrentPage, reviewsForReport, PageLimit }) => {
    const [showMore, setShowMore] = useState(false)

    const pages = Array(Math.ceil(reviewsForReport.length / PageLimit)).fill();

    const maxVisiblePages = 3;
    let firstVisiblePage = 1;
    let lastVisiblePage = pages.length;

    if (reviewsForReport.length > maxVisiblePages * PageLimit) {
        firstVisiblePage = showMore ? 1 : Math.max(currentPage - maxVisiblePages + 1, 1);
        lastVisiblePage = showMore ? pages.length : Math.min(currentPage + maxVisiblePages - 1, pages.length);
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => {
                        setCurrentPage(currentPage - 1); smoothScrollToTop();}}>Previous</button>
                </li>
                {
                    pages.map((_, index) => {
                        const pageNumber = index + 1;
                        if (pageNumber >= firstVisiblePage && pageNumber <= lastVisiblePage) {
                            return (
                                <li key={index} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => {
                                        setCurrentPage(pageNumber); smoothScrollToTop();}}>{pageNumber}</button>
                                </li>
                            );
                        }
                        return null;
                    })
                }
                <li className={`page-item ${currentPage === pages.length ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => {
                        setCurrentPage(currentPage + 1); smoothScrollToTop();}}>Next</button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
