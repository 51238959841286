// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import $ from 'jquery';
// import 'datatables.net';
// import 'datatables.net-dt/css/jquery.dataTables.css';
// import { useNavigate } from 'react-router-dom';
// import { API_BASE_URL } from '../api';
// import { hashedApiKey } from '../api';


// export default function ReviewClassificationCleaning() {
//     const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')))
//     const [brandNameList, setBrandNameList] = useState([]);
//     const [reviewsList, setReviewsList] = useState([]);
//     const [productList, setProductList] = useState([]);
//     const [factorList, setFactorList] = useState([]);
//     const [filter, setFilter] = useState([]);
//     const reviewsTableRef = useRef(null);
//     const dataTableRef = useRef(null);

//     const navigate = useNavigate();
//     const [selectedBrand, setSelectedBrand] = useState(null);
//     const [selectedBrandName, setSelectedBrandName] = useState("Select a Brand");
//     const [selectedProduct, setSelectedProduct] = useState(null);
//     const [selectedProductName, setSelectedProductName] = useState("Select a Product");
//     const [selectedFactor, setSelectedFactor] = useState(null);
//     const [selectedFactorName, setSelectedFactorName] = useState("Select a Factor");

//     useEffect(() => {
//         const validateAdmin = async () => {
//             try {
//                 const response = await axios.get(API_BASE_URL + '/api/validate-admin', {
//                     params: { isAdmin: isAdmin },
//                     headers: { 'x-api-key': hashedApiKey }
//                 });

//                 if (!response.data.isValid) {
//                     localStorage.setItem('admin', JSON.stringify(response.data.isValid));
//                     setIsAdmin(response.data.isValid)
//                     navigate('/SignIn');
//                     // window.location.reload();
//                 }
//             } catch (error) {
//                 // Handle error if any
//             }
//         };

//         validateAdmin();
//     }, [isAdmin]);


//     useEffect(() => {
//         axios.get(API_BASE_URL + '/api/getBrandList',
//             { headers: { 'x-api-key': hashedApiKey } }).then((response) => {
//                 setBrandNameList(response.data);
//             });
//     }, []);

//     useEffect(() => {
//         if (selectedFactor) {
//             axios.get(API_BASE_URL + '/api/getReviewByProductIDFactorID', { params: { productID: selectedProduct, factorID: selectedFactor }, headers: { 'x-api-key': hashedApiKey } })
//                 .then((response) => {
//                     setReviewsList(response.data);
//                     console.log(response.data)
//                 });
//         }
//     }, [selectedFactor]);

//     useEffect(() => {
//         if (selectedBrand === '*') {
//             // Handle the case where 'All Brands' is selected
//             // You can fetch all products here or reset the product list
//             setProductList([]); // Set an empty array initially, replace with actual product data
//         } else {
//             // Fetch products for the selected brand
//             axios.get(API_BASE_URL + '/api/getProductsByBrandID', { params: { brandID: selectedBrand }, headers: { 'x-api-key': hashedApiKey } })
//                 .then((response) => {
//                     setProductList(response.data);
//                 });
//         }
//     }, [selectedBrand]);


// useEffect(() => {
//     // Fetch Factors for the selected product id
//     axios.get(API_BASE_URL + '/api/getFactorsByProductID', { params: { productID: selectedProduct }, headers: { 'x-api-key': hashedApiKey } })
//         .then((response) => {
//             setFactorList(response.data);
//         });
// }, [selectedProduct]);

//     const handleRemoveReview = async (reviewId,factorId) => {
//         if (window.confirm('Are you sure you want to remove this review?')) {
//             try {
//                 await axios.delete(API_BASE_URL + `/api/removeReview`, { params: { reviewID: reviewId, factorID:factorId }, headers: { 'x-api-key': hashedApiKey } });

//                 // Remove the review from the local state
//                 const updatedReviews = reviewsList.filter((review) => review.Review_ID !== reviewId);
//                 setReviewsList(updatedReviews);
//             } catch (error) {
//                 console.error(error);
//             }
//         }
//     };


//     const handleBrandSelect = (brandID) => {
//         setSelectedBrand(brandID);
//         setSelectedBrandName(brandID === '*' ? "All Brands" : brandNameList.find((brand) => brand.Brand_ID === brandID)?.Brand_Name || "Select a Brand");
//         setSelectedProduct(null); // Reset selected product when changing brands
//     };

//     const handleProductSelect = (productID) => {
//         setSelectedProduct(productID);
//         setSelectedProductName(productList.find((product) => product.Product_ID === productID)?.Product_Name || "Select a Product");
//         setSelectedFactor(null);
//     };

//     const handleFactorSelect = (factorID) => {
//         setSelectedFactor(factorID);
//         setSelectedFactorName(factorList.find((factor) => factor.Factor_ID === factorID)?.Factor_Name || "Select a Factor")
//     };


//     return (
//         <div className="container mt-3">
//             <div className="form-group">
//                 <div className="col-12 col-md-4 mb-3">
//                     <div className="dropdown">
//                         <button
//                             className="btn btn-secondary dropdown-toggle"
//                             type="button"
//                             id="brandDropdownButton"
//                             data-toggle="dropdown"
//                             aria-haspopup="true"
//                             aria-expanded="false"
//                         >
//                             {selectedBrandName}
//                         </button>
//                         <div className="dropdown-menu" aria-labelledby="brandDropdownButton" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
//                             <a className="dropdown-item" href="#" onClick={() => handleBrandSelect('*')}>
//                                 All Brands
//                             </a>
//                             {brandNameList.map((brand) => (
//                                 <a className="dropdown-item" href="#" onClick={() => handleBrandSelect(brand.Brand_ID)} key={brand.Brand_ID}>
//                                     {brand.Brand_Name}
//                                 </a>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-12 col-md-4 mb-3">
//                     {selectedBrand && (
//                         <div className="dropdown">
//                             <button
//                                 className="btn btn-secondary dropdown-toggle"
//                                 type="button"
//                                 id="productDropdownButton"
//                                 data-toggle="dropdown"
//                                 aria-haspopup="true"
//                                 aria-expanded="false"
//                             >
//                                 {selectedProductName}
//                             </button>
//                             <div className="dropdown-menu" aria-labelledby="productDropdownButton" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
//                                 {/* Fetch and render products for the selected brand */}
//                                 {productList.map((product) => (
//                                     <a className="dropdown-item" href="#" onClick={() => handleProductSelect(product.Product_ID)} key={product.Product_ID}>
//                                         {product.Product_Name}
//                                     </a>
//                                 ))}
//                             </div>
//                         </div>
//                     )}
//                 </div>

//                 {selectedProduct && (
//                     <div className="col-12 col-md-4 mb-3">
//                         <div className="dropdown">
//                             <button
//                                 className="btn btn-secondary dropdown-toggle"
//                                 type="button"
//                                 id="factorDropdownButton"
//                                 data-toggle="dropdown"
//                                 aria-haspopup="true"
//                                 aria-expanded="false"
//                             >
//                                 {selectedFactorName}
//                             </button>
//                             <div className="dropdown-menu" aria-labelledby="factorDropdownButton">
//                                 {/* Add your factors here */}
//                                 {factorList.map((factor) => (
//                                     <a className="dropdown-item" href="#" onClick={() => handleFactorSelect(factor.Factor_ID)} key={factor.Factor_ID}>
//                                         {factor.Factor_Name}
//                                     </a>
//                                 ))}
//                             </div>
//                         </div>
//                     </div>
//                 )}

//                 <div className="table-responsive">
//                     <table className="table" ref={reviewsTableRef}>
//                         <thead className="thead-dark">
//                             <tr>
//                                 <th scope="col">#</th>
//                                 <th scope="col">Brand Review</th>
//                                 <th scope="col">Senteez Score</th>
//                                 <th scope="col">Action</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {reviewsList.map((data, index) => (
//                                 <tr key={index}>
//                                     <th scope="row">{data.Review_ID}</th>
//                                     <td className="col-8 col-sm-8 col-md-8">{data.Product_Review}</td>
//                                     <td className="col-1 col-sm-1 d-sm-table-cell col-md-2 text-center">{data.Senteez_Score}</td>
//                                     <td className="col-1 col-sm-1 col-md-2 d-md-table-cell text-center">
//                                         <button className='btn btn-sm btn-danger' onClick={() => handleRemoveReview(data.Review_ID,data.Factor_ID)}>remove</button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     )
// }


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { API_BASE_URL } from '../api';
// import { hashedApiKey } from '../api';

// export default function ReviewClassificationCleaning() {
//     const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')));
//     const [brandNameList, setBrandNameList] = useState([]);
//     // const [reviewsList, setReviewsList] = useState([]);
//     const [factorList, setFactorList] = useState([]);
//     const [productList, setProductList] = useState([]);
//     const [reviewByFactorList, setReviewByFactorList] = useState([]);
//     const [selectedBrand, setSelectedBrand] = useState(null);
//     const [selectedProduct, setSelectedProduct] = useState(null);
//     const [selectedBrandName, setSelectedBrandName] = useState("Select a Brand");
//     const [selectedProductName, setSelectedProductName] = useState("Select a Product");

//     const navigate = useNavigate();

//     // Validate Admin Access
//     useEffect(() => {
//         const validateAdmin = async () => {
//             try {
//                 const response = await axios.get(`${API_BASE_URL}/api/validate-admin`, {
//                     params: { isAdmin },
//                     headers: { 'x-api-key': hashedApiKey }
//                 });

//                 if (!response.data.isValid) {
//                     localStorage.setItem('admin', JSON.stringify(response.data.isValid));
//                     setIsAdmin(response.data.isValid);
//                     navigate('/SignIn');
//                 }
//             } catch (error) {
//                 console.error(error);
//             }
//         };

//         validateAdmin();
//     }, [isAdmin]);

//     // Fetch Brand List
//     useEffect(() => {
//         axios.get(`${API_BASE_URL}/api/getBrandList`, { headers: { 'x-api-key': hashedApiKey } })
//             .then((response) => {
//                 setBrandNameList(response.data);
//             });
//     }, []);

//     // Fetch Factors for the selected product id
//     useEffect(() => {
//         if (selectedProduct) {
//             axios.get(`${API_BASE_URL}/api/getFactorsByProductID`, {
//                 params: { productID: selectedProduct },
//                 headers: { 'x-api-key': hashedApiKey }
//             })
//                 .then((response) => {
//                     setFactorList(response.data);
//                 })
//                 .catch((error) => {
//                     console.error('Error fetching factors:', error);
//                 });
//         } else {
//             // Clear factors if no product is selected
//             setFactorList([]);
//         }
//     }, [selectedProduct]);


//     useEffect(() => {
//         if (selectedProduct) {
//             axios.get(`${API_BASE_URL}/api/getReviewsWithFactors`, {
//                 params: { productID: selectedProduct },
//                 headers: { 'x-api-key': hashedApiKey }
//             })
//                 .then((response) => {
//                     setReviewByFactorList(response.data);
//                 })
//                 .catch((error) => {
//                     console.error('Error fetching factors:', error);
//                 });
//         } else {
//             // Clear factors if no product is selected
//             setReviewByFactorList([]);
//         }
//     }, [selectedProduct]);

//     // Fetch Products by Selected Brand
//     useEffect(() => {
//         if (selectedBrand) {
//             axios.get(`${API_BASE_URL}/api/getProductsByBrandID`, {
//                 params: { brandID: selectedBrand },
//                 headers: { 'x-api-key': hashedApiKey }
//             })
//                 .then((response) => {
//                     setProductList(response.data);
//                 });
//         }
//     }, [selectedBrand]);

//     // // Fetch Reviews by Product ID
//     // useEffect(() => {
//     //     if (selectedProduct) {
//     //         axios.get(`${API_BASE_URL}/api/getReviewByProductID`, {
//     //             params: { productID: selectedProduct },
//     //             headers: { 'x-api-key': hashedApiKey }
//     //         })
//     //             .then((response) => {
//     //                 setReviewsList(response.data);
//     //             });
//     //     }
//     // }, [selectedProduct]);

//     // Handle Brand Selection
//     const handleBrandSelect = (brandID) => {
//         setSelectedBrand(brandID);
//         setSelectedBrandName(brandID === '*' ? "All Brands" : brandNameList.find((brand) => brand.Brand_ID === brandID)?.Brand_Name || "Select a Brand");
//         setSelectedProduct(null); // Reset selected product
//     };

//     // Handle Product Selection
//     const handleProductSelect = (productID) => {
//         setSelectedProduct(productID);
//         setSelectedProductName(productList.find((product) => product.Product_ID === productID)?.Product_Name || "Select a Product");
//     };


//     // Function to handle changes in the factor assignments
//     const handleFactorChange = async (reviewId, factorId, isChecked) => {
//         try {
//             // Optimistic UI update
//             setReviewByFactorList((prevList) =>
//                 prevList.map((review) => {
//                     if (review.Review_ID === reviewId) {
//                         const updatedFactors = isChecked
//                             ? [...review.Factor_IDs, factorId] // Add factor
//                             : review.Factor_IDs.filter((id) => id !== factorId); // Remove factor
//                         return { ...review, Factor_IDs: updatedFactors };
//                     }
//                     return review;
//                 })
//             );

//             // Make the API request to reflect the change
//             if (isChecked) {
//                 // Adding a factor
//                 await axios.post(`${API_BASE_URL}/api/addReviewToFactor`, {
//                     reviewID: reviewId,
//                     factorID: factorId
//                 }, {
//                     headers: { 'x-api-key': hashedApiKey },
//                     withCredentials: true
//                 });
//             } else {
//                 // Removing a factor
//                 await axios.delete(`${API_BASE_URL}/api/removeReviewFromFactor`, {
//                     params: { reviewID: reviewId, factorID: factorId },
//                     headers: { 'x-api-key': hashedApiKey },
//                     withCredentials: true
//                 });
//             }
//         } catch (error) {
//             console.error('Error updating review-factor association:', error);

//             // Roll back the optimistic update
//             setReviewByFactorList((prevList) =>
//                 prevList.map((review) => {
//                     if (review.Review_ID === reviewId) {
//                         const originalFactors = isChecked
//                             ? review.Factor_IDs.filter((id) => id !== factorId) // Revert addition
//                             : [...review.Factor_IDs, factorId]; // Revert removal
//                         return { ...review, Factor_IDs: originalFactors };
//                     }
//                     return review;
//                 })
//             );
//         }
//     };

//     // Render the Component
//     return (
//         <div className="container mt-3">
//             <div className="form-group">
//                 <div className="col-12 col-md-4 mb-3">
//                     <div className="dropdown">
//                         <button
//                             className="btn btn-secondary dropdown-toggle"
//                             type="button"
//                             id="brandDropdownButton"
//                             data-toggle="dropdown"
//                             aria-haspopup="true"
//                             aria-expanded="false"
//                         >
//                             {selectedBrandName}
//                         </button>
//                         <div className="dropdown-menu" aria-labelledby="brandDropdownButton">
//                             {brandNameList.map((brand) => (
//                                 <a className="dropdown-item" href="#" onClick={() => handleBrandSelect(brand.Brand_ID)} key={brand.Brand_ID}>
//                                     {brand.Brand_Name}
//                                 </a>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-12 col-md-4 mb-3">
//                     {selectedBrand && (
//                         <div className="dropdown">
//                             <button
//                                 className="btn btn-secondary dropdown-toggle"
//                                 type="button"
//                                 id="productDropdownButton"
//                                 data-toggle="dropdown"
//                                 aria-haspopup="true"
//                                 aria-expanded="false"
//                             >
//                                 {selectedProductName}
//                             </button>
//                             <div className="dropdown-menu" aria-labelledby="productDropdownButton">
//                                 {productList.map((product) => (
//                                     <a className="dropdown-item" href="#" onClick={() => handleProductSelect(product.Product_ID)} key={product.Product_ID}>
//                                         {product.Product_Name}
//                                     </a>
//                                 ))}
//                             </div>
//                         </div>
//                     )}
//                 </div>

//                 <div className="table-responsive">
//                     <table className="table">
//                         <thead className="thead-dark">
//                             <tr>
//                                 <th scope="col" className="col-1">#</th>
//                                 <th scope="col" className="col-5">Brand Review</th>
//                                 <th scope="col" className="col-6">Factors</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {reviewByFactorList.map((review, index) => (
//                                 <tr key={index}>
//                                     <th scope="row">{review.Review_ID}</th>
//                                     <td>{review.Product_Review}</td>
//                                     <td>
//                                         <div className="row">
//                                             {factorList.map((factor) => {
//                                                 // Determine if the current factor is associated with this review
//                                                 const isChecked = review.Factor_IDs && review.Factor_IDs.includes(factor.Factor_ID);

//                                                 return (
//                                                     <div key={factor.Factor_ID} className="col-6 mb-2">
//                                                         <label className="d-inline-flex align-items-center">
//                                                             <input
//                                                                 type="checkbox"
//                                                                 checked={isChecked}
//                                                                 onChange={(e) => handleFactorChange(review.Review_ID, factor.Factor_ID, e.target.checked)}
//                                                             />
//                                                             <span style={{ marginLeft: '8px' }}>{factor.Factor_Name}</span>
//                                                         </label>
//                                                     </div>
//                                                 );
//                                             })}
//                                         </div>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// }

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../api';
import { hashedApiKey } from '../api';

export default function ReviewClassificationCleaning() {
    const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')));
    const [brandNameList, setBrandNameList] = useState([]);
    const [factorList, setFactorList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [reviewByFactorList, setReviewByFactorList] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedBrandName, setSelectedBrandName] = useState("Select a Brand");
    const [selectedProductName, setSelectedProductName] = useState("Select a Product");
    const [reviewByLLama3LLMFactors, setReviewByLLama3LLMFactors] = useState([]);
    const [reviewByMistralLLMFactors, setReviewByMistralLLMFactors] = useState([]);
    const [reviewByOpenchatLLMFactors, setReviewByOpenchatLLMFactors] = useState([]);
    const [reviewByCodellamaLLMFactors, setReviewByCodellamaLLMFactors] = useState([]);
    const [reviewByZephyrLLMFactors, setReviewByZephyrLLMFactors] = useState([]);
    const [reviewByYiLLMFactors, setReviewByYiLLMFactors] = useState([]);
    const [reviewByGemma2LLMFactors, setReviewByGemma2LLMFactors] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const validateAdmin = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/validate-admin`, {
                    params: { isAdmin },
                    headers: { 'x-api-key': hashedApiKey }
                });
                if (!response.data.isValid) {
                    localStorage.setItem('admin', JSON.stringify(response.data.isValid));
                    setIsAdmin(response.data.isValid);
                    navigate('/SignIn');
                }
            } catch (error) {
                console.error(error);
            }
        };
        validateAdmin();
    }, [isAdmin]);

    useEffect(() => {
        axios.get(`${API_BASE_URL}/api/getBrandList`, { headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                setBrandNameList(response.data);
            })
            .catch((error) => console.error('Error fetching brands:', error));
    }, []);

    useEffect(() => {
        if (selectedProduct) {
            axios.get(`${API_BASE_URL}/api/getFactorsByProductID`, {
                params: { productID: selectedProduct },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => setFactorList(response.data))
                .catch((error) => console.error('Error fetching factors:', error));

            axios.get(`${API_BASE_URL}/api/getReviewsWithFactors`, {
                params: { productID: selectedProduct },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => setReviewByFactorList(response.data))
                .catch((error) => console.error('Error fetching reviews:', error));

            axios.get(`${API_BASE_URL}/api/getLLama3LLMResults`, {
                params: { productID: selectedProduct },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByLLama3LLMFactors(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));

            axios.get(`${API_BASE_URL}/api/getMistralLLMResults`, {
                params: { productID: selectedProduct },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByMistralLLMFactors(response.data)
                    console.log(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));


            axios.get(`${API_BASE_URL}/api/getOpenchatLLMResults`, {
                params: { productID: selectedProduct },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByOpenchatLLMFactors(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));

            axios.get(`${API_BASE_URL}/api/getCodellamaLLMResults`, {
                params: { productID: selectedProduct },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByCodellamaLLMFactors(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));

            axios.get(`${API_BASE_URL}/api/getZephyrLLMResults`, {
                params: { productID: selectedProduct },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByZephyrLLMFactors(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));

            axios.get(`${API_BASE_URL}/api/getYiLLMResults`, {
                params: { productID: selectedProduct },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByYiLLMFactors(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));

            axios.get(`${API_BASE_URL}/api/getGemma2LLMResults`, {
                params: { productID: selectedProduct },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByGemma2LLMFactors(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));

        } else {
            setFactorList([]);
            setReviewByFactorList([]);
            setReviewByLLama3LLMFactors([]);
            setReviewByMistralLLMFactors([]);
            setReviewByOpenchatLLMFactors([]);
            setReviewByCodellamaLLMFactors([]);
            setReviewByZephyrLLMFactors([]);
            setReviewByYiLLMFactors([]);
            setReviewByGemma2LLMFactors([]);
        }
    }, [selectedProduct]);

    useEffect(() => {
        if (selectedBrand) {
            axios.get(`${API_BASE_URL}/api/getProductsByBrandID`, {
                params: { brandID: selectedBrand },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => setProductList(response.data))
                .catch((error) => console.error('Error fetching products:', error));
        }
    }, [selectedBrand]);

    const handleBrandSelect = (brandID) => {
        setSelectedBrand(brandID);
        setSelectedBrandName(brandID === '*' ? "All Brands" : brandNameList.find((brand) => brand.Brand_ID === brandID)?.Brand_Name || "Select a Brand");
        setSelectedProduct(null);
    };

    const handleProductSelect = (productID) => {
        setSelectedProduct(productID);
        setSelectedProductName(productList.find((product) => product.Product_ID === productID)?.Product_Name || "Select a Product");
    };

    const handleFactorChange = debounce(async (reviewId, factorId, isChecked) => {
        try {
            // Optimistic UI update
            setReviewByFactorList((prevList) =>
                prevList.map((review) => {
                    if (review.Review_ID === reviewId) {
                        const updatedFactors = isChecked
                            ? [...review.Factor_IDs, factorId] // Add factor
                            : review.Factor_IDs.filter((id) => id !== factorId); // Remove factor
                        return { ...review, Factor_IDs: updatedFactors };
                    }
                    return review;
                })
            );

            // Make the API request to reflect the change
            if (isChecked) {
                // Adding a factor
                await axios.post(`${API_BASE_URL}/api/addReviewToFactor`, {
                    reviewID: reviewId,
                    factorID: factorId
                }, {
                    headers: { 'x-api-key': hashedApiKey },
                    withCredentials: true
                });
            } else {
                // Removing a factor
                await axios.delete(`${API_BASE_URL}/api/removeReviewFromFactor`, {
                    params: { reviewID: reviewId, factorID: factorId },
                    headers: { 'x-api-key': hashedApiKey },
                    withCredentials: true
                });
            }
        } catch (error) {
            console.error('Error updating review-factor association:', error);
            alert('Failed to update factor association. Please try again.');

            // Roll back the optimistic update
            setReviewByFactorList((prevList) =>
                prevList.map((review) => {
                    if (review.Review_ID === reviewId) {
                        const originalFactors = isChecked
                            ? review.Factor_IDs.filter((id) => id !== factorId) // Revert addition
                            : [...review.Factor_IDs, factorId]; // Revert removal
                        return { ...review, Factor_IDs: originalFactors };
                    }
                    return review;
                })
            );
        }
    }, 300); // 300ms debounce delay

    const tableStyles = {
        tableLayout: 'fixed',
        width: '100%',
    };

    const cellStyles = {
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    return (
        <div className="container mt-3">
            <div className="form-group">
                <div className="col-12 col-md-4 mb-3">
                    <div className="dropdown">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="brandDropdownButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {selectedBrandName}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="brandDropdownButton">
                            {brandNameList.map((brand) => (
                                <a className="dropdown-item" href="#" onClick={() => handleBrandSelect(brand.Brand_ID)} key={brand.Brand_ID}>
                                    {brand.Brand_Name}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    {selectedBrand && (
                        <div className="dropdown">
                            <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="productDropdownButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {selectedProductName}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="productDropdownButton">
                                {productList.map((product) => (
                                    <a className="dropdown-item" href="#" onClick={() => handleProductSelect(product.Product_ID)} key={product.Product_ID}>
                                        {product.Product_Name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="table-responsive">
                    <table className="table" style={tableStyles}>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col" className="col-1" style={cellStyles}>#</th>
                                <th scope="col" className="col-5" style={cellStyles}>Brand Review</th>
                                <th scope="col" className="col-6" style={cellStyles}>Factors</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reviewByFactorList.map((review, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <th scope="row" className="text-truncate" style={cellStyles}>{review.Review_ID}</th>
                                        <td className="text-truncate" style={cellStyles}>{review.Product_Review}</td>
                                        <td>
                                            <div className="row">
                                                {factorList.map((factor) => {
                                                    const isChecked = review.Factor_IDs && review.Factor_IDs.includes(factor.Factor_ID);

                                                    return (
                                                        <div key={factor.Factor_ID} className="col-6 mb-2 text-truncate">
                                                            <label className="d-inline-flex align-items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isChecked}
                                                                    onChange={(e) => handleFactorChange(review.Review_ID, factor.Factor_ID, e.target.checked)}
                                                                />
                                                                <span className="ml-2">{factor.Factor_Name}</span>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">
                                            <p>
                                                <strong>LLama3</strong> = {
                                                    reviewByLLama3LLMFactors.find(llama3Review => llama3Review.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p>
                                            <p>
                                                <strong>Mistral</strong> = {
                                                    reviewByMistralLLMFactors.find(mistralReview => mistralReview.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p>
                                            {/* <p>
                                                <strong>Yi</strong> = {
                                                    reviewByYiLLMFactors.find(yiReview => yiReview.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p> */}
                                            <p>
                                                <strong>Gemma2</strong> = {
                                                    reviewByGemma2LLMFactors.find(Gemma2Review => Gemma2Review.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p>
                                            <p>
                                                <strong>Mixtral</strong> = {
                                                    reviewByOpenchatLLMFactors.find(openchatReview => openchatReview.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p>
                                            {/* <p>
                                                <strong>Codellama</strong> = {
                                                    reviewByCodellamaLLMFactors.find(codellamaReview => codellamaReview.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p>
                                            <p>
                                                <strong>Zephyr</strong> = {
                                                    reviewByZephyrLLMFactors.find(zephyrReview => zephyrReview.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p> */}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}


{/* <div className="table-responsive">
                    
                    <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col" className="col-1">#</th>
                                <th scope="col" className="col-5">Brand Review</th>
                                <th scope="col" className="col-6">Factors</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reviewByFactorList.map((review, index) => (
                                <tr key={index}>
                                    <th scope="row">{review.Review_ID}</th>
                                    <td>{review.Product_Review}</td>
                                    <td>
                                        <div className="row">
                                            {factorList.map((factor) => {
                                                const isChecked = review.Factor_IDs && review.Factor_IDs.includes(factor.Factor_ID);

                                                return (
                                                    <div key={factor.Factor_ID} className="col-6 mb-2">
                                                        <label className="d-inline-flex align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={isChecked}
                                                                onChange={(e) => handleFactorChange(review.Review_ID, factor.Factor_ID, e.target.checked)}
                                                            />
                                                            <span style={{ marginLeft: '8px' }}>{factor.Factor_Name}</span>
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="factor-lists full-width">
                                            <p>LLama3_product_review_factor = Food Quality, Service</p>
                                            <p>Mistral_product_review_factor = Ambience, Cleanliness and Hygiene, Food Quality, Menu Variety, Service </p>
                                            <p>Mixtral_product_review_factor = Ambience, Food Quality</p>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> */}


