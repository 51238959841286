import React from 'react'
import { Link } from 'react-router-dom';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';
import style from './DashboardInstructorContent/InstructorContent.module.css'

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

export default function ReactDonetChart({ name, positive, total, index, setPage, setFilter }) {
    const colorPalette = [['rgba(3, 126, 243, 1)', 'rgba(243, 244, 247, 0.3)'], ['rgba(255, 200, 69, 1)', 'rgba(243, 244, 247, 0.3)'], ['rgba(0, 193, 110, 1)', 'rgba(243, 244, 247, 0.3)'], ['rgba(117, 82, 204, 1)', 'rgba(243, 244, 247, 0.3)']]
    const negative = total - positive

    const data = {
        labels: ['Positive', 'Negative'],
        datasets: [{
            label: 'reviews',
            data: [positive, negative],
            backgroundColor: colorPalette[index],
            borderColor: "black",
            borderWidth: 2,
        }]
    }
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: false
        },
        cutout: 40
    }

    const plugins = [{
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 150).toFixed(2);
            ctx.font = `bold ${fontSize}rem sans-serif`;
            ctx.textBaseline = "top";
            var text = `${Math.round(positive / total * 100)}%`,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    }]

    const handleClick = (index) => {
        switch (index) {
            case 0:
                setFilter({ product: '*', factor: 'Is_Ingredients_Review', score: '*' })
                break;
            case 1:
                setFilter({ product: '*', factor: 'Is_Skin_Compatibility_Review', score: '*' })
                break;
            case 2:
                setFilter({ product: '*', factor: 'Is_Packaging_Review', score: '*' })
                break;
            case 3:
                setFilter({ product: '*', factor: 'Is_Value_For_Money_Review', score: '*' })
                break;
        }
    }

    return (
        <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '120px' }}>
                    <Doughnut data={data} options={options} plugins={plugins} />
                </div>
                <h5 class={`${style.text_muted} mt-4`}>{name}</h5>
                <h6 class={`${style.text_muted}`}>{((positive / total) * 5).toFixed(1)} / 5.0</h6>
                <Link onClick={() => { setPage('reports'); handleClick(index) }}><h6>{total} Reviews</h6></Link>
            </div>
        </div>
    )
}


