// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { API_BASE_URL } from '../api';
// import { hashedApiKey } from '../api';

// export default function ReviewClassificationCleaning() {
//     const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')));
//     const [factorNameList, setFactorNameList] = useState([]);
//     const [restaurantsRankingList, setRestaurantsRankingList] = useState([]);
//     const [selectedFactorName, setSelectedFactorName] = useState("Select a Brand");
//     const [selectedFactor, setSelectedFactor] = useState(null);

//     const navigate = useNavigate();

//     useEffect(() => {
//         const validateAdmin = async () => {
//             try {
//                 const response = await axios.get(`${API_BASE_URL}/api/validate-admin`, {
//                     params: { isAdmin },
//                     headers: { 'x-api-key': hashedApiKey }
//                 });
//                 if (!response.data.isValid) {
//                     localStorage.setItem('admin', JSON.stringify(response.data.isValid));
//                     setIsAdmin(response.data.isValid);
//                     navigate('/SignIn');
//                 }
//             } catch (error) {
//                 console.error(error);
//             }
//         };
//         validateAdmin();
//     }, [isAdmin]);

//     useEffect(() => {
//         axios.get(`${API_BASE_URL}/api/getRestaurantFactorList`, { headers: { 'x-api-key': hashedApiKey } })
//             .then((response) => {
//                 setFactorNameList(response.data);
//             })
//             .catch((error) => console.error('Error fetching factors:', error));
//     }, []);

//     useEffect(() => {
//         if (selectedFactor) {
//             axios.get(`${API_BASE_URL}/api/getRestaurantsRankingDetail`, {
//                 params: { factorID: selectedFactor },
//                 headers: { 'x-api-key': hashedApiKey }
//             })
//                 .then((response) => setRestaurantsRankingList(response.data))
//                 .catch((error) => console.error('Error fetching Restaurants Ranking List:', error));
//         }
//     }, [selectedFactor]);

//     const handleFactorSelect = (factorID) => {
//         setSelectedFactor(factorID);
//         setSelectedFactorName(factorID === '*' ? "All Factors" : factorNameList.find((factorName) => factorName.Factor_ID === factorID)?.Factor_Name || "Select a Factor");
//     };

//     const tableStyles = {
//         tableLayout: 'fixed',
//         width: '100%',
//     };

//     const cellStyles = {
//         whiteSpace: 'normal',
//         overflow: 'hidden',
//         textOverflow: 'ellipsis',
//     };

//     return (
//         <div className="container mt-3">
//             <div className="form-group">
//                 <div className="col-12 col-md-4 mb-3">
//                     <div className="dropdown">
//                         <button
//                             className="btn btn-secondary dropdown-toggle"
//                             type="button"
//                             id="brandDropdownButton"
//                             data-toggle="dropdown"
//                             aria-haspopup="true"
//                             aria-expanded="false"
//                         >
//                             {selectedFactorName}
//                         </button>
//                         <div className="dropdown-menu" aria-labelledby="brandDropdownButton">
//                             {factorNameList.map((factorName) => (
//                                 <a className="dropdown-item" href="#" onClick={() => handleFactorSelect(factorName.Factor_ID)} key={factorName.Factor_ID}>
//                                     {factorName.Factor_Name}
//                                 </a>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//                 <div className="table-responsive">
//                     <table className="table" style={tableStyles}>
//                         <thead className="thead-dark">
//                             <tr>
//                                 <th scope="col" className="col-4" style={cellStyles}>Restaurants</th>
//                                 <th scope="col" className="col-4" style={cellStyles}>Overall</th>
//                                 <th scope="col" className="col-4" style={cellStyles}>Factor</th>
//                             </tr>
//                         </thead>

//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// }



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../api';
import { hashedApiKey } from '../api';

export default function ReviewClassificationCleaning() {
    const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')));
    const [factorNameList, setFactorNameList] = useState([]);
    const [restaurantsRankingList, setRestaurantsRankingList] = useState([]);
    const [selectedFactorName, setSelectedFactorName] = useState("Select a Factor");
    const [selectedFactor, setSelectedFactor] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const validateAdmin = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/validate-admin`, {
                    params: { isAdmin },
                    headers: { 'x-api-key': hashedApiKey }
                });
                if (!response.data.isValid) {
                    localStorage.setItem('admin', JSON.stringify(response.data.isValid));
                    setIsAdmin(response.data.isValid);
                    navigate('/SignIn');
                }
            } catch (error) {
                console.error(error);
            }
        };
        validateAdmin();
    }, [isAdmin]);

    useEffect(() => {
        axios.get(`${API_BASE_URL}/api/getRestaurantFactorList`, { headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                setFactorNameList(response.data);
            })
            .catch((error) => console.error('Error fetching factors:', error));
    }, []);

    useEffect(() => {
        if (selectedFactor) {
            axios.get(`${API_BASE_URL}/api/getRestaurantsRankingDetail`, {
                params: { factorID: selectedFactor },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => setRestaurantsRankingList(response.data))
                .catch((error) => console.error('Error fetching Restaurants Ranking List:', error));
        }
    }, [selectedFactor]);

    const handleFactorSelect = (factorID) => {
        setSelectedFactor(factorID);
        setSelectedFactorName(factorID === '*' ? "All Factors" : factorNameList.find((factorName) => factorName.Factor_ID === factorID)?.Factor_Name || "Select a Factor");
    };

    const tableStyles = {
        tableLayout: 'fixed',
        width: '100%',
    };

    const cellStyles = {
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    const headerSpanStyles = {
        margin: '0 8px', // Adds 10 pixels of space on the left and right of each span
    };

    return (
        <div className="container mt-3">
            <div className="form-group">
                <div className="col-12 col-md-4 mb-3">
                    <div className="dropdown">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="brandDropdownButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {selectedFactorName}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="brandDropdownButton">
                            {factorNameList.map((factorName) => (
                                <a className="dropdown-item" href="#" onClick={() => handleFactorSelect(factorName.Factor_ID)} key={factorName.Factor_ID}>
                                    {factorName.Factor_Name}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table" style={tableStyles}>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col" className="col-4" style={cellStyles}>Restaurants</th>
                                <th scope="col" className="col-4" style={cellStyles}>
                                    Overall
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <span style={headerSpanStyles}>Total</span>
                                        <span style={headerSpanStyles}>Positive</span>
                                        <span style={headerSpanStyles}>TTC_Score</span>
                                    </div>
                                </th>
                                <th scope="col" className="col-4" style={cellStyles}>
                                    Factor
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <span style={headerSpanStyles}>psf_Total</span>
                                        <span style={headerSpanStyles}>psf_Positive</span>
                                        <span style={headerSpanStyles}>psf_TTC_Score</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {restaurantsRankingList.map((restaurant, index) => (
                                <tr key={index}>
                                    <td>{restaurant.Brand_Name}</td>
                                    <td>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <span style={headerSpanStyles}>{restaurant.Total}</span>
                                            <span style={headerSpanStyles}>{restaurant.Positive}</span>
                                            <span style={headerSpanStyles}>{restaurant.TTC_Score}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <span style={headerSpanStyles}>{restaurant.psf_Total}</span>
                                            <span style={headerSpanStyles}>{restaurant.psf_Positive}</span>
                                            <span style={headerSpanStyles}>{restaurant.psf_TTC_Score}</span>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
