import React, { useState } from 'react'
import style from './Main.module.css'
import Navbar from '../DashboardNavbar/Navbar'
import InstructorContent from '../DashboardInstructorContent/InstructorContent'
import ReactBarChart from '../ReactBarChart'
import CourseContent from '../DashboardCourseContent/CourseContent'
import DashboardReviewsReport from '../DashboardReviewsReports/DashboardReviewsReport'
import OCES from '../DashboardOCES/OCES'

// id = brand_id
// setProductID
// productID

export default function Main({ brand_id, page, setPage, productID, setProductID, topRecommendations,isOpen,setIsOpen, setTopRecommendations, setTopReviews, topReviews, setFilter, filter }) {
  const [instructorId, setInstructorId] = useState("");
  const [reportTopReviews, setReportTopReviews] = useState("");
  const [reportCourseID, setReportCourseID] = useState('');

  return (
    <div className={`${style.main}`}>
      <Navbar setIsOpen={setIsOpen} isOpen={isOpen}/>
      {page === 'Brand' && <InstructorContent setFilter={setFilter} brand_id={brand_id} setPage={setPage} setProductID={setProductID} setInstructorId={setInstructorId} setTopReviews={setTopReviews} setTopRecommendations={setTopRecommendations} />}
      {page === 'Product' && <CourseContent setFilter={setFilter} setReportTopReviews={setReportTopReviews} setReportCourseID={setReportCourseID} productID={productID} setPage={setPage} setTopRecommendations={setTopRecommendations} />}
      {page === 'reports' && <DashboardReviewsReport filter={filter} setFilter={setFilter} brand_id={brand_id} reportTopReviews={reportTopReviews} reportCourseID={reportCourseID} instructorId={instructorId} topReviews={topReviews} topRecommendations={topRecommendations} />}
      {page === 'OCES' && <OCES setFilter={setFilter} brand_id={brand_id} setPage={setPage} />}
      {/* <InstructorContent id = {id} /> */}
    </div>
  )
}
