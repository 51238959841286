import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../api';
import { hashedApiKey } from '../api';


export default function RetailReviewsCleaning() {
    const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')))
    const [brandNameList, setBrandNameList] = useState([]);
    const [reviewsList, setReviewsList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [filter, setFilter] = useState([]);
    const reviewsTableRef = useRef(null);
    const navigate = useNavigate();
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedBrandName, setSelectedBrandName] = useState("Select a Brand");
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductName, setSelectedProductName] = useState("Select a Product");


    useEffect(() => {
        const validateAdmin = async () => {
            try {
                const response = await axios.get(API_BASE_URL + '/api/validate-admin', {
                    params: { isAdmin:isAdmin },
                    headers: { 'x-api-key': hashedApiKey }
                });
                
                if (!response.data.isValid){
                    localStorage.setItem('admin', JSON.stringify(response.data.isValid));
                    setIsAdmin(response.data.isValid)
                    navigate('/SignIn');
                    // window.location.reload();
                }
            } catch (error) {
                // Handle error if any
            }
        };

        validateAdmin();
    }, [isAdmin]);

    // useEffect(() => {
    //     window.location.reload();
    // },[]);

    useEffect(() => {
        axios.get(API_BASE_URL + '/api/getBrandList',
            { headers: { 'x-api-key': hashedApiKey } }).then((response) => {
                setBrandNameList(response.data);
            });
    }, []);

    // useEffect(() => {
    //     if (selectedProduct) {
    //         axios.get(API_BASE_URL + '/api/getReviewByProductID', { params: { productID: selectedProduct }, headers: { 'x-api-key': hashedApiKey } })
    //             .then((response) => {
    //                 setReviewsList(response.data);
    //             });
    //     }
    // }, [selectedProduct]);

    useEffect(() => {
        if (selectedProduct) {
            axios.get(API_BASE_URL + '/api/getReviewByProductID', { params: { productID: selectedProduct }, headers: { 'x-api-key': hashedApiKey } })
                .then((response) => {
                    // Sort the data based on 'Senteez_Score' column in ascending order
                    const sortedData = response.data.sort((a, b) => a.Senteez_Score - b.Senteez_Score);
    
                    // Update the reviewsList state with the sorted data
                    setReviewsList(sortedData);
                });
        }
    }, [selectedProduct]);

    useEffect(() => {
        if (selectedBrand === '*') {
            // Handle the case where 'All Brands' is selected
            // You can fetch all products here or reset the product list
            setProductList([]); // Set an empty array initially, replace with actual product data
        } else {
            // Fetch products for the selected brand
            axios.get(API_BASE_URL + '/api/getProductsByBrandID', { params: { brandID: selectedBrand }, headers: { 'x-api-key': hashedApiKey } })
                .then((response) => {
                    setProductList(response.data);
                });
        }
    }, [selectedBrand]);

    // useEffect(() => {
    //     if (reviewsList.length > 0) {
    //         $(reviewsTableRef.current).DataTable();
    //     }
    // }, [reviewsList]);

    const updateSenteezScore = async (index, model, reviewId, newValue) => {
        const newReviewsList = [...reviewsList];
        newReviewsList[index][model] = newValue;
        setReviewsList(newReviewsList);
        try {
            await axios.put(API_BASE_URL + '/api/updateScore', { Senteez_Score: newValue, reviewId: reviewId }, { headers: { 'x-api-key': hashedApiKey } });
        } catch (error) {
            console.error(error);
        }
    };
    const handleBrandSelect = (brandID) => {
        setSelectedBrand(brandID);
        setSelectedBrandName(brandID === '*' ? "All Brands" : brandNameList.find((brand) => brand.Brand_ID === brandID)?.Brand_Name || "Select a Brand");
        setSelectedProduct(null); // Reset selected product when changing brands
    };

    const handleProductSelect = (productID) => {
        setSelectedProduct(productID);
        setSelectedProductName(productList.find((product) => product.Product_ID === productID)?.Product_Name || "Select a Product");
    };


    return (
        <div className="container mt-3">
            <div className="form-group">
                <div className="col-12 col-md-4 mb-3">
                    <div className="dropdown">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="brandDropdownButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {selectedBrandName}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="brandDropdownButton" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                            <a className="dropdown-item" href="#" onClick={() => handleBrandSelect('*')}>
                                All Brands
                            </a>
                            {brandNameList.map((brand) => (
                                <a className="dropdown-item" href="#" onClick={() => handleBrandSelect(brand.Brand_ID)} key={brand.Brand_ID}>
                                    {brand.Brand_Name}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    {selectedBrand && (
                        <div className="dropdown">
                            <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="productDropdownButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {selectedProductName}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="productDropdownButton" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                                {/* Fetch and render products for the selected brand */}
                                {productList.map((product) => (
                                    <a className="dropdown-item" href="#" onClick={() => handleProductSelect(product.Product_ID)} key={product.Product_ID}>
                                        {product.Product_Name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="table-responsive">
                    <table className="table" ref={reviewsTableRef}>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Brand Review</th>
                                <th scope="col">Senteez_Score</th>
                                <th scope="col">F-Bert</th>
                                <th scope="col">O-Bert</th>
                                <th scope="col">Davinci</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reviewsList.map((data, index) => (
                                <tr key={index}>
                                    <th scope="row">{data.Review_ID}</th>
                                    <td className="col-8 col-sm-8 col-md-7">{data.Product_Review}</td>
                                    <td className="col-1 col-sm-1 d-md-table-cell text-center">
                                        <select
                                            className="form-control form-control-sm"
                                            value={data.Senteez_Score}
                                            onChange={(e) => updateSenteezScore(index, 'Senteez_Score', data.Review_ID, e.target.value)}
                                        >
                                            <option value="">--Select--</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </td>
                                    <td className="col-1 col-sm-1 d-sm-table-cell text-center">{data.Sentiment_Score_M1}</td>
                                    <td className="col-1 col-sm-1 d-md-table-cell text-center">{data.Sentiment_Score_M2}</td>
                                    <td className="col-1 col-sm-1 d-md-table-cell text-center">{data.Sentiment_Score_M3}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
