import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';


import ReactBarChart from '../ReactBarChart'
import Header from './InstructorHeaders/Header';
import style from './InstructorContent.module.css'
import ReactPieChart from '../ReactPieChart';
import ReactCompatitorChart from '../ReactCompatitorChart';
import ReactMultiLineChart from '../ReactMultiLineChart'
import { hashedApiKey } from '../../api';
import { API_BASE_URL } from '../../api';



export default function InstructorContent({ brand_id, setPage, setInstructorId, setProductID, setFilter }) {
    const [brandStarCount, setBrandStarCount] = useState([])
    const [headerData, setHeaderData] = useState([])
    const [brandProductsDetail, setBrandProductsDetail] = useState([])
    const [brandTopRecommendations, setBrandTopRecommendations] = useState([])
    const [brandSummaryInsights, setBrandSummaryInsights] = useState([])
    const [lineChartData, setLineChartData] = useState({})
    const [brandData, setBrandData] = useState({
        InstructorOverall: 0,
        InstructorContent: 0,
        InstructorEngagement: 0,
        InstructorPractice: 0,
        InstructorCareerBenefit: 0
    });

    const [competitorData, setCompetitorData] = useState({
        CompetitorOverall: 0,
        CompetitorIngredients: 0,
        CompetitorPackaging: 0,
        CompetitorValueForMoney: 0,
        CompetitorSkinCompatibility: 0
    });

    const [brandInsights, setBrandInsights] = useState({
        insight_1_result: 0,
        insight_1_grade: false,
        insight_2_result: 0,
        insight_2_grade: false
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
        let compatitorPositive = 0
        let brandPositive = 0
        Axios.get(API_BASE_URL + '/api/getBrandSummary', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            let starCount = response.data.map(record => record.Star_Count)[0]
            starCount = starCount.split(/[,\s]/).map(Number)
            setBrandStarCount(starCount)
            // setInstructorSummary(response.data)
            const BrandOverall = Math.round((response.data[0].Positive / response.data[0].Total) * 100)
            const BrandOverallTotal = response.data[0].Total
            brandPositive = response.data[0].Positive
            setBrandData(prevState => ({ ...prevState, BrandOverall, BrandOverallTotal }));
        })
        Axios.get(API_BASE_URL + '/api/getBrandName', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            const BrandName = response.data[0].Brand_Name
            setBrandData(prevState => ({ ...prevState, BrandName }));
        })

        //     // ------------------------compatitor data ----------------------

        Axios.get(API_BASE_URL + '/api/getCompetitorSummary', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                const CompetitorName = response.data[0].Brand_Name
                const CompetitorOverall = Math.round((response.data[0].Positive / response.data[0].Total) * 100);
                const CompetitorOverallTotal = response.data[0].Total
                compatitorPositive = response.data[0].Positive
                setCompetitorData(prevState => ({ ...prevState, CompetitorOverall, CompetitorOverallTotal, CompetitorName }));

                // Percentage difference = ((80 - 100) / 100) * 100%
                let percentage = ((brandPositive - compatitorPositive) / compatitorPositive) * 100
                let insight_3_result = percentage.toFixed(1)
                setBrandInsights(prevState => ({ ...prevState, insight_3_result }))
            });
        Axios.get(API_BASE_URL + '/api/getCompetitorSummaryByFactors', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                const CompetitorIngredients = Math.round((response.data[0].Positive / response.data[0].Total) * 100);
                const CompetitorIngredientsTotal = response.data[0].Total
                const CompetitorSkinCompatibility = Math.round((response.data[1].Positive / response.data[1].Total) * 100);
                const CompetitorSkinCompatibilityTotal = response.data[1].Total
                const CompetitorPackaging = Math.round((response.data[2].Positive / response.data[2].Total) * 100);
                const CompetitorPackagingTotal = response.data[2].Total
                const CompetitorValueForMoney = Math.round((response.data[3].Positive / response.data[3].Total) * 100);
                const CompetitorValueForMoneyTotal = response.data[3].Total

                setCompetitorData(prevState => ({ ...prevState, CompetitorIngredients, CompetitorIngredientsTotal, CompetitorPackaging, CompetitorPackagingTotal, CompetitorValueForMoney, CompetitorValueForMoneyTotal, CompetitorSkinCompatibility, CompetitorSkinCompatibilityTotal }));
            });
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getBrandLineChart', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            const data = response.data;
            const reviewMonths = data.map(item => item.review_month).slice(0, -1).slice(-12);
            const formattedDates = reviewMonths.map(dateStr => {
                const [year, month] = dateStr.split('-');
                const monthName = new Date(`${dateStr}-01`).toLocaleString('default', { month: 'short' });
                return `${monthName} ${year}`;
            });
            const positiveReviews = data.map(item => item.positive_reviews).slice(0, -1).slice(-12);
            const negativeReviews = data.map(item => item.negative_reviews).slice(0, -1).slice(-12);
            const neutralReviews = data.map(item => item.neutral_reviews).slice(0, -1).slice(-12);
            const LinechartData = {
                formattedDates,
                positiveReviews,
                negativeReviews,
                neutralReviews
            };
            setLineChartData(LinechartData)
            // const _6_month = LinechartData.positiveReviews.slice(-6)


            // -------------------- insight no 1 ----------------------
            let previous_3_months_sum = LinechartData.positiveReviews.slice(-6)[0] + LinechartData.positiveReviews.slice(-6)[1] + LinechartData.positiveReviews.slice(-6)[2]
            let last_3_months_sum = LinechartData.positiveReviews.slice(-6)[3] + LinechartData.positiveReviews.slice(-6)[4] + LinechartData.positiveReviews.slice(-6)[5]
            let absoluteDiff = Math.abs(last_3_months_sum - previous_3_months_sum);
            let avg = (last_3_months_sum + previous_3_months_sum) / 2;
            let percentage = (absoluteDiff / avg) * 100;
            let insight_1_result = percentage.toFixed(1);
            let insight_1_grade = false

            if (last_3_months_sum > previous_3_months_sum) {
                insight_1_grade = true
            } else {
                insight_1_grade = false
            }
            // -------------------- insight no 2 ----------------------
            const previous_1_months_sum = LinechartData.positiveReviews.slice(-2)[0] + LinechartData.negativeReviews.slice(-2)[0] + LinechartData.neutralReviews.slice(-2)[0]
            const last_1_months_sum = LinechartData.positiveReviews.slice(-2)[1] + LinechartData.negativeReviews.slice(-2)[1] + LinechartData.neutralReviews.slice(-2)[1]
            absoluteDiff = Math.abs(last_1_months_sum - previous_1_months_sum);
            avg = (last_1_months_sum + previous_1_months_sum) / 2;
            percentage = (absoluteDiff / avg) * 100;
            let insight_2_result = percentage.toFixed(1);
            let insight_2_grade = false
            if (last_1_months_sum > previous_1_months_sum) {
                insight_2_grade = true
            } else {
                insight_2_grade = false
            }

            setBrandInsights(prevState => ({ ...prevState, insight_1_result, insight_1_grade, insight_2_result, insight_2_grade }))
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getHeaderData', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setHeaderData(response.data)
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getBrandProductsDetail', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setBrandProductsDetail(response.data)
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getBrandTopRecommendations', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setBrandTopRecommendations(response.data)
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getbrandInsights', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setBrandSummaryInsights(JSON.parse(response.data[0].key_themes))
        })
    }, [])

    useEffect(() => {
        if (brandProductsDetail.length > 0) {
            $('#example').DataTable()
        }
    }, [brandProductsDetail]);


    const modalStyles = {
        modalLg: {
            maxWidth: '80%',
        },
        modalHeader: {
            backgroundColor: '#007bff',
            color: 'white',
        },
        modalTitle: {
            fontWeight: 'bold',
        },
        modalBody: {
            fontSize: '1.1em',
            minHeight: '50vh',
        },
        listStyleNone: {
        },
        listItem: {
            marginBottom: '15px',
        },
        textMuted: {
            color: '#6c757d !important',
        },
    };

    let counter = 0;
    return (
        <main class={style.content} >
            <div class="container p-0 mt-3">
                <h1 class="mb-3"><strong>{brandData.BrandName}</strong></h1>
                <div class={`${style.card} flex-fill w-100`}>
                    <div class="card-body d-flex flex-column flex-lg-row">
                        <div className='col-md-12 col-lg-7 col-xl-8 mt-3 order-md-1'>
                            {headerData.map((data, index) => (
                                <p key={index} className={`${style.text_muted} mb-3`}>
                                    {brandData.BrandName} has an overall rating of {data.TTC_Score.toFixed(1)} out of 5.0 based on {brandData.BrandOverallTotal} total reviews analyzed.
                                </p>
                            ))}
                            <h5 class={`${style.card_title}`}>Key Insights:</h5>
                            <ul>
                                {brandSummaryInsights.slice(0, 3).map((insight, index) => (
                                    <li key={index} className={`${style.text_muted}`}>
                                        {insight}
                                    </li>
                                ))}
                            </ul>
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#insightsModal">
                                View All
                            </button>

                            <div className="modal fade" id="insightsModal" tabIndex="-1" role="dialog" aria-labelledby="insightsModalLabel" aria-hidden="true">
                                <div className="modal-dialog" style={modalStyles.modalLg} role="document">
                                    <div className="modal-content">
                                        <div className="modal-header" style={modalStyles.modalHeader}>
                                            <h5 className="modal-title" id="insightsModalLabel" style={modalStyles.modalTitle}>All Insights</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body" style={modalStyles.modalBody}>
                                            <ul style={modalStyles.listStyleNone}>
                                                {brandSummaryInsights.map((insight, index) => (
                                                    <li key={index} style={{ ...modalStyles.textMuted, ...modalStyles.listItem }}>
                                                        {insight}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-12 col-lg-5 col-xl-4 mt-2 order-md-2`}>
                            <ReactPieChart StarCount={brandStarCount} />
                        </div>
                    </div>
                </div>

                <div className="row mb-3 mt-3" >
                    <div className='col-lg-12 col-xl-6'>
                        <div className='row d-flex'>
                            {headerData.map((data) => (
                                <>
                                    <div className='col-6 mb-4'>
                                        <Header name={'Rating'} value={`${Number(data.TTC_Score.toFixed(1))} / 5.0`} icon={'fa-regular fa-star'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Total Reviews'} value={data.Total} icon={'fa-regular fa-face-smile'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Positive Reviews'} value={((data.Positive / data.Total) * 100).toFixed(0) + '%'} icon={'fa-regular fa-thumbs-up'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Branches Analyzed'} value={data.productCount} icon={'fa-solid fa-check'} />
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>

                    <div class="col-lg-12 col-xl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`} style={{ display: 'inline-block' }}>Overall Score (1-5)</h5><p className='ml-1' style={{ display: 'inline-block', padding: 0, margin: 0, }}>based on sentiment analysis</p>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <ReactBarChart StarCount={brandStarCount} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* line chart */}
                <div className="row">
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-3 mb-3">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h4 class={`${style.card_title}`}>Track the Pulse: Sentiment Trends Over Time</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Stay ahead of the curve and monitor sentiment trends over time with our intuitive sentiment analysis graph.</p>
                                <ReactMultiLineChart lineChartData={lineChartData} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* courses */}
                <div className="row">
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div className={`${style.card} flex-fill`}>
                            <div class={`${style.card_header} align-center`}>
                                <h4 class={`${style.card_title}  mb-0`}>My Branches</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>List of {brandData.BrandName} branches analyzed, with links to access individual branch dashboard</p>
                                <div class="table-responsive">
                                    <table id='example' className={`${style.table} table table-hover my-0`}>
                                        <thead>
                                            <tr>
                                                <th className="d-sm-table-cell col-7 col-sm-9 col-md-7">Branch Name</th>
                                                <th className="col-2 col-sm-1 d-none d-sm-none d-md-table-cell text-center">Total Reviews</th>
                                                <th className="col-2 col-sm-1 d-none d-sm-none d-md-table-cell text-center">Total Positive</th>
                                                <th className="col-2 col-sm-1 d-md-table-cell text-center">Rating</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {brandProductsDetail.map((data, index) => (
                                                <tr key={index}>
                                                    <td className="d-sm-table-cell col-7 col-sm-9 col-md-7"><Link onClick={() => { setPage('Product'); setProductID(data.Product_ID) }}>{data.Product_Name}</Link></td>
                                                    <td className="col-2 col-sm-1 d-none d-sm-none d-md-table-cell text-center">{data.Total}</td>
                                                    <td className="col-2 col-sm-1 d-none d-sm-none d-md-table-cell text-center">{data.Positive}</td>
                                                    <td className="col-2 col-sm-1 d-md-table-cell text-center">{data.TTC_Score.toFixed(1)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`row`}>
                    <div class="col-12 d-flex justify-content-center mb-3">
                        <div class="text-center">
                            <p class={`${style.text_muted}`}>
                                Follow Us on
                                <a href="https://www.linkedin.com/company/senteez/" target="_blank" class="mx-1">LinkedIn</a> |
                                <a href="https://www.instagram.com/senteezanalysis/" target="_blank" class="mx-1">Instagram</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
