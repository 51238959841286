import React, { useState, useEffect } from 'react'
import Axios from 'axios'

import ReactStackedBarChart from '../ReactStackedBarChart'
import ReactDonetChart from '../ReactDonetChart';
import style from '../DashboardInstructorContent/InstructorContent.module.css'
import { hashedApiKey } from '../../api';


export default function OCES({ brand_id, setPage, setFilter }) {
    const [brandName, setBrandName] = useState([])
    const [donutTotal, setDonutTotal] = useState([])
    const [donutPositive, setDonutPositive] = useState([])
    const [donutName, setDonutName] = useState([])
    const [instructorFactorsDetail, setInstructorFactorsDetail] = useState({})
    const [instructorData, setInstructorData] = useState({
        InstructorOverall: 0,
        InstructorContent: 0,
        InstructorEngagement: 0,
        InstructorPackaging: 0,
        InstructorCareerBenefit: 0
    });
    const [competitorData, setCompetitorData] = useState({
        CompetitorOverall: 0,
        CompetitorContent: 0,
        CompetitorEngagement: 0,
        CompetitorPractice: 0,
        CompetitorCareerBenefit: 0
    });

    const [brandInsights, setBrandInsights] = useState({
        insight_1_result: 0,
        insight_1_grade: false,
        insight_2_result: 0,
        insight_2_grade: false
    });

    // useEffect(() => {
    //     Axios.get('http://localhost:5000/api/getBrandName', { params: { brandID: brand_id}, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
    //         setBrandName(response.data)
    //     })
    // })

    // useEffect(() => {
    //     Axios.get('http://localhost:5000/api/getBrandSummaryByFactors', { params: { brandID: brand_id}, headers: { 'x-api-key': hashedApiKey } })
    //         .then((response) => {
    //             const factorsdetail = {
    //                 'positive': response.data.map(record => record.Positive),
    //                 'negative': response.data.map(record => record.Negative),
    //                 'neutral': response.data.map(record => record.Neutral)
    //             }
    //             setDonutName(response.data.map(record => record.Factor_Name).slice(0, -1))
    //             setDonutPositive(response.data.map(record => record.Positive).slice(0, -1))
    //             setDonutTotal(response.data.map(record => record.Total).slice(0, -1))
    //             setInstructorFactorsDetail(factorsdetail)
                
    //             const brandIngredientsPositive = response.data[0].Positive
    //             const brandPackagingPositive = response.data[2].Positive
    //             Axios.get('http://localhost:5000/api/getCompetitorSummaryByFactors', { params: { brandID: brand_id}, headers: { 'x-api-key': hashedApiKey } })
    //                 .then((response) => {
    //                     const competitorIngredientsPositive = response.data[0].Positive
    //                     const competitorPackagingPositive = response.data[2].Positive
    //                     const percentage_1 = ((brandIngredientsPositive - competitorIngredientsPositive) / competitorIngredientsPositive) * 100
    //                     const insight_1_result = percentage_1.toFixed(1)
    //                     const percentage_2 = ((brandPackagingPositive - competitorPackagingPositive) / competitorPackagingPositive) * 100
    //                     const insight_2_result = percentage_2.toFixed(1)
    //                     setBrandInsights(prevState => ({ ...prevState, insight_1_result, insight_2_result }))
    //                 });
    //         });

    //     Axios.get('http://localhost:5000/api/getOCSELineChart', { params: { brandID: brand_id}, headers: { 'x-api-key': hashedApiKey } })
    //         .then((response) => {
    //             const data = response.data;
    //             const reviewMonths = data.map(item => item.review_month).slice(0, -1).slice(-12);
    //             const formattedDates = reviewMonths.map(dateStr => {
    //                 const [year, month] = dateStr.split('-');
    //                 const monthName = new Date(`${dateStr}-01`).toLocaleString('default', { month: 'short' });
    //                 return `${monthName} ${year}`;
    //             });
    //             const pIngredients = data.map(item => item.ingredients_positive_reviews).slice(0, -1).slice(-12);
    //             const pSkinCompatibility = data.map(item => item.skin_Compatibility_positive_reviews).slice(0, -1).slice(-12);
    //             const pPackaging = data.map(item => item.packaging_positive_reviews).slice(0, -1).slice(-12);
    //             const pValueForMoney = data.map(item => item.value_For_Money_positive_reviews).slice(0, -1).slice(-12);
    //             const LinechartData = {
    //                 formattedDates,
    //                 pIngredients,
    //                 pSkinCompatibility,
    //                 pPackaging,
    //                 pValueForMoney
    //             };
    //             let previous_3_months_sum = LinechartData.pSkinCompatibility.slice(-6)[0] + LinechartData.pSkinCompatibility.slice(-6)[1] + LinechartData.pSkinCompatibility.slice(-6)[2]
    //             let last_3_months_sum = LinechartData.pSkinCompatibility.slice(-6)[3] + LinechartData.pSkinCompatibility.slice(-6)[4] + LinechartData.pSkinCompatibility.slice(-6)[5]
    //             let absoluteDiff = Math.abs(last_3_months_sum - previous_3_months_sum);
    //             let avg = (last_3_months_sum + previous_3_months_sum) / 2;
    //             let percentage = (absoluteDiff / avg) * 100;
    //             let insight_3_result = percentage.toFixed(1);
    //             let insight_3_grade = false
    //             if (last_3_months_sum > previous_3_months_sum) {
    //                 insight_3_grade = true
    //             } else {
    //                 insight_3_grade = false
    //             }
    //             setBrandInsights(prevState => ({ ...prevState, insight_3_result, insight_3_grade }))
    //         });


    // }, [])


    return (
        <main class={style.content}>
            <div class="container-flubrand_idp-0 mt-3">
                <h1 class="mb-3">
                    <strong>{brandName.map((data) => (
                        data.Brand_Name
                    ))}
                    </strong>
                </h1>
                {/* <div class={`${style.card} flex-fill w-100`}>
                    <div class="card-body d-flex">
                        <div className='col-md-12 col-lg-12 mt-3'>
                            <p class={`${style.text_muted} `}>We have analyzed user opinions related to 4 product analysis factors including <strong>Ingredients</strong>, <strong>Skin Compatibility</strong>, <strong>Packaging</strong> and <strong>Value For Money</strong>.</p>
                            <h5 class={`${style.card_title}`}>Key Insights:</h5>
                            <ul>
                                <li class={`${style.text_muted}`}>Your ingredients got {brandInsights.insight_1_result >= 0 ? `${brandInsights.insight_1_result}% more` : ` ${-brandInsights.insight_1_result}% fewer`} positive reviews than your top competitor.</li>
                                <li class={`${style.text_muted}`}>
                                    Over the last 3 months, the number of positive reviews you've received has
                                    {brandInsights.insight_3_grade ? ` increased by ${brandInsights.insight_3_result}% ` : ` decreased by ${brandInsights.insight_3_result}% `}
                                    compared to the previous period.
                                </li>
                                <li class={`${style.text_muted}`} >Your Packaging got {brandInsights.insight_2_result >= 0 ? `${brandInsights.insight_2_result}% more` : ` ${-brandInsights.insight_2_result}% fewer`} positive reviews than your top competitor.</li>
                            </ul>
                        </div>
                    </div>
                </div> */}

                {/* <div className="row mt-3">
                    <div class="col-12 col-xxl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_body} py-4`}>
                                <div class={`${style.card_body} row`}>
                                    {donutTotal.map((data, index) => (
                                        <>
                                            <ReactDonetChart setPage={setPage} setFilter={setFilter} total={data} positive={donutPositive[index]} name={donutName[index].replace(/_/g, ' ')} index={index} />
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}


                {/* <div className="row mt-3">
                    <div class="col-12 col-xxl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`}>Product Analysis Factors</h5>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Analyzing Products Effectiveness: Breakdown of Sentiment by Key Factors</p>
                                <ReactStackedBarChart FactorsDetail={instructorFactorsDetail} />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </main>

    )
}
