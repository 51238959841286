import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../api';
import { hashedApiKey } from '../api';

export default function DataEntry() {
    // const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')))
    const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')))
    const [brandSuccessMessage, setbrandSuccessMessage] = useState('');
    const [insertedBrandId, setInsertedBrandId] = useState(null);
    const [brandName, setBrandName] = useState('');
    const [brandList, setBrandList] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const validateAdmin = async () => {
            try {
                const response = await axios.get(API_BASE_URL + '/api/validate-admin', {
                    params: { isAdmin:isAdmin },
                    headers: { 'x-api-key': hashedApiKey }
                });
                
                if (!response.data.isValid){
                    // console.log(response.data.isValid)
                    localStorage.setItem('admin', JSON.stringify(response.data.isValid));
                    setIsAdmin(response.data.isValid)
                    navigate('/SignIn');
                    // window.location.reload();
                }
            } catch (error) {
                // Handle error if any
            }
        };

        validateAdmin();
    }, [isAdmin]);

    
    const handleBrandSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(API_BASE_URL + '/api/insertBrandName', { brandName }, {
                headers: { 'x-api-key': hashedApiKey }
            });

            if (response.status === 200) {
                setbrandSuccessMessage('Successfully inserted');
                setInsertedBrandId(response.data.brand_id);

                // Clear the text field
                setBrandName('');

                // Refresh the table data by fetching it again
                fetchBrandList();
            }
        } catch (error) {
            setbrandSuccessMessage('Error inserting brand name');
            console.error(error);
        }
    };

    const fetchBrandList = async () => {
        try {
            const response = await axios.get(API_BASE_URL + '/api/getBrandList', { headers: { 'x-api-key': hashedApiKey } });
            setBrandList(response.data.reverse());
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchBrandList();
    }, []);


    return (
        <>
            <div className='mt-5 mb-5'>
                <div class="container" style={{ border: '2px solid black', padding: '20px', borderRadius: '5px' }}>
                    <h3 className='mb-3'>Brand Entry</h3>
                    <form onSubmit={handleBrandSubmit}>
                        <div class="form-group">
                            <label htmlFor="brandName"><strong>Brand Name:</strong></label>
                            <input type="text" class="form-control col-md-6" id="brandName" onChange={(e) => setBrandName(e.target.value)} />
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                        {brandSuccessMessage && <span className="text-success ml-2">{brandSuccessMessage}</span>}
                        {brandSuccessMessage && <span className="text-primary ml-2">Brand ID: {insertedBrandId}</span>}
                    </form>
                </div>
            </div>
            <div>
                <table className="table">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Brand Name</th>
                            <th scope="col">Total Products</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {brandList.map((data, index) => {
                            return (
                                <tr key={data.Brand_ID}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{data.Brand_Name}</td>
                                    <td>{data.total_products}</td>
                                    <td>
                                        <Link
                                            className="btn btn-primary"
                                            to={`/productdetail/${data.Brand_ID}`}
                                        >
                                            Detail
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}