import React, { useEffect } from 'react'
import Dropdown from './Dropdown'

export default function DashboardReviewsReport({ brand_id, topReviews, topRecommendations, reportCourseID, reportTopReviews, filter, setFilter }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='container'>
            <Dropdown filter={filter} setFilter={setFilter} brand_id={brand_id} reportTopReviews={reportTopReviews} reportCourseID={reportCourseID} topReviews={topReviews} topRecommendations={topRecommendations} />
        </div>
    )
}
