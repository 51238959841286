import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios'
import Pagination from '../Pagination';
import { hashedApiKey } from '../../api';
import { API_BASE_URL } from '../../api';

const PageLimit = 25;

export default function Dropdown({ setFilter, filter, brand_id, topReviews, topRecommendations, reportCourseID, reportTopReviews }) {
    const [reviewsForReport, setReviewsForReport] = useState([]);
    const [productNameList, setProducrNameList] = useState([])
    const [evaluatingFactorList, setEvaluatingFactorList] = useState([])

    useEffect(() => {
        Axios.get(API_BASE_URL+'/api/getProducts', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setProducrNameList(response.data.map(record => ({ id: record.Product_ID, name: record.Product_Name })))
        })
    }, [brand_id])

    useEffect(() => {
        Axios.get(API_BASE_URL+'/api/getEvaluatingFactors', { params: { brandID: brand_id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setEvaluatingFactorList(Array.from(new Set(response.data.map(record => JSON.stringify({ id: record.Factor_ID, name: record.Factor_Name }))), JSON.parse))
        })
    }, [brand_id])


    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * PageLimit;
    const endIndex = startIndex + PageLimit;
    const currentPageData = reviewsForReport.slice(startIndex, endIndex);
    

    const handleCoursesChange = (event) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            product: event.target.value
        }));
    }
    const handleFactorsChange = (event) => {
        // console.log(event.target.value)
        setFilter(prevFilter => ({
            ...prevFilter,
            factor: event.target.value
            
        }));
    }
    const handleScoreChange = (event) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            score: event.target.value
        }));
    }

    useEffect(() => {
        Axios.get(API_BASE_URL+'/api/getReviewsForReport', { params: { brandID: brand_id, productID: filter.product, factorName: filter.factor, score: filter.score }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setReviewsForReport(response.data)
            console.log(response.data)
        })
    }, [filter])

    return (
        <div className='container mt-3'>
            <div class="form-group row">
                <div className='col-12 col-md-4 mb-3'>
                    <label>Choose Product: </label>
                    <select name="Products" class="form-control" onChange={handleCoursesChange} value={filter.product}>
                        <option value='*'>All Product</option>
                        {productNameList.map((product) => (
                            <option value={product.id}>{product.name}</option>
                        ))}
                    </select>
                </div>
                <div className='col-12 col-md-4 mb-3'>
                    <label>Effectiveness Factors:</label>
                    <select name="TTC factor" class="form-control" onChange={handleFactorsChange} value={filter.factor} >
                        <option value="*" >Overall Reviews</option>
                        {evaluatingFactorList.map((factor) => (
                            <option key={factor.id} value={factor.name}>{factor.name.replace(/_/g, ' ')}</option>
                        ))}
                    </select>
                </div>
                <div className='col-12 col-md-4 mb-3'>
                    <label>Senteez Score:</label>
                    <select name="Score" class="form-control" onChange={handleScoreChange} value={filter.score}>
                        <option value="*">All Scores</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </div>
            </div>
            <div class="table-responsive">
                <table className="table">
                    <thead className="thead-dark">
                        <tr>
                            {/* <th class="text-center d-none d-sm-none d-md-table-cell">#</th> */}
                            <th class="col-2 col-sm-2 col-md-2 d-sm-table-cell text-center">Review By</th>
                            <th class="col-11 col-sm-10 col-md-8 text-center">Review</th>
                            <th class="d-none d-sm-none col-md-2 d-md-table-cell text-center">Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentPageData.map((data) => (
                                <tr>
                                    <td class="col-1 col-sm-1 d-sm-table-cell text-center">{data.Display_Name}</td>
                                    <td class="col-11 col-sm-10 col-md-7">{data.Product_Review}</td>
                                    <td class="d-none d-sm-none d-md-table-cell text-center">{data.Senteez_Score}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <div className="mr-3">
                    <p className="mb-0">Showing {startIndex} to {endIndex} of {reviewsForReport.length} entries</p>
                </div>
                <div>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        reviewsForReport={reviewsForReport}
                        PageLimit={PageLimit}
                    />
                </div>
            </div>
        </div>
    )
}

