import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Sidebar from '../DashboardSideBar/Sidebar'
import style from './Dashboard.module.css'
import Main from '../DashboardMain/Main'
import { API_BASE_URL } from '../../api';
import { hashedApiKey } from '../../api'

// [productID, setProductID]

export default function Dashboard() {
  const [brand_id, setBrandId] = useState(JSON.parse(localStorage.getItem('brand')));
  const [page, setPage] = useState('Brand');
  const [productID, setProductID] = useState('');
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [topReviews, setTopReviews] = useState("");
  const [topRecommendations, setTopRecommendations] = useState('');
  const [filter, setFilter] = useState({
    product: '*',
    factor: '*',
    score: '*'
  });

  useEffect(() => {
    const validateBrand = async () => {
      try {
        const response = await axios.get(API_BASE_URL + '/api/validate-brand', {
          params: { brandId: brand_id },
          headers: { 'x-api-key': hashedApiKey }
        });

        if (!response.data.isValid) {
          if (response.data.sessionBrandId === undefined) {
            navigate('/SignIn');
            localStorage.clear();
          } else {
            // console.log(JSON.stringify(response.data.sessionBrandId))
            localStorage.setItem('brand', JSON.stringify(response.data.sessionBrandId));
            setBrandId(response.data.sessionBrandId);
          }

          // Show alert message
          // alert("⛔ Unauthorized access");

          window.location.reload();
        }
      } catch (error) {
        // Handle error if any
      }
    };

    validateBrand();
  }, [brand_id]);

  // const brand_id = JSON.parse(localStorage.getItem('brand'))

  return (
    <div className={`${style.wrapper}`}>
      <Sidebar isOpen={isOpen} setFilter={setFilter} setPage={setPage} setTopRecommendations={setTopRecommendations} setProductID={setProductID} brand_id={brand_id} setTopReviews={setTopReviews} />
      <Main isOpen={isOpen} setIsOpen={setIsOpen} filter={filter} setFilter={setFilter} brand_id={brand_id} page={page} setPage={setPage} setProductID={setProductID} productID={productID} topRecommendations={topRecommendations} setTopRecommendations={setTopRecommendations} topReviews={topReviews} setTopReviews={setTopReviews} />
    </div>
  )
}
