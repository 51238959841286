import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../api';
import { hashedApiKey } from '../api';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function ProductDetailInsertion() {
  const { Brand_ID } = useParams();
  const [channels, setChannels] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [productList, setProductList] = useState([]);
  const [organizedCategories, setOrganizedCategories] = useState([]);
  const [productsSuccessMessage, setProductSuccessMessage] = useState('');
  const [products, setProducts] = useState([
    {
      productName: '',
      productUrls: [''],
      channelNames: [''],
      categoryName: '',
      productID: ''
    },
  ]);

  const handleProductMoreButtonClick = () => {
    setProducts((prevProducts) => [
      ...prevProducts,
      {
        productName: '',
        productUrls: [''],
        channelNames: [''],
        categoryName: '',
        productID: ''
      },
    ]);
  };

  const addChannelAndUrl = (productIndex, urlIndex) => {
    const updatedProducts = [...products];
    updatedProducts[productIndex].productUrls.push('');
    updatedProducts[productIndex].channelNames.push('');
    setProducts(updatedProducts);
  };

  const handleProductInputChange = (productIndex, field, urlIndex, value) => {
    const updatedProducts = [...products];
    if (field === 'productUrls' || field === 'channelNames') {
      updatedProducts[productIndex][field][urlIndex] = value; // Update the specific URL or channel name
    } else {
      updatedProducts[productIndex][field] = value;
    }
    setProducts(updatedProducts);
  };

  useEffect(() => {
    axios.get(API_BASE_URL + '/api/getChannelsCategorys', {
      headers: { 'x-api-key': hashedApiKey },
    })
      .then((response) => {
        if (response.status === 200) {
          setChannels(response.data.channels);
          setCategorys(response.data.Categorys);

          // Organize the data
          const organizedData = [];
          response.data.Categorys.forEach((category) => {
            const existingCategory = organizedData.find(
              (c) => c.Categoryid === category.Categoryid
            );

            if (!existingCategory) {
              organizedData.push({
                Categoryid: category.Categoryid,
                Categoryname: category.Categoryname,
                subcategories: [
                  {
                    SubCategoryid: category.SubCategoryid,
                    SubCategoryname: category.SubCategoryname,
                  },
                ],
              });
            } else {
              existingCategory.subcategories.push({
                SubCategoryid: category.SubCategoryid,
                SubCategoryname: category.SubCategoryname,
              });
            }
          });

          setOrganizedCategories(organizedData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [Brand_ID]);

  const handleProductsSubmit = (e) => {
    e.preventDefault();
    axios.put(API_BASE_URL + '/api/insertProductDetails', { products, Brand_ID }, {
      headers: { 'x-api-key': hashedApiKey },
    })
      .then((response) => {
        setProductSuccessMessage('Successfully inserted');
      })
      .catch((error) => {
        setProductSuccessMessage('Error inserting Product name');
        console.error(error);
      });
  };

  const fetchBrandList = async () => {
    try {
      const response = await axios.get(API_BASE_URL + '/api/getProducts', { params: { brandID: Brand_ID }, headers: { 'x-api-key': hashedApiKey } });
      setProductList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBrandList();
  }, []);

  const handleAddNewChannelToExisting = (Product_ID) => {
    // Fetch product details (name, category) based on the productId
    // Make an API request to get the product details
    axios.get(API_BASE_URL + `/api/getProductName`, { params: { productID: Product_ID }, headers: { 'x-api-key': hashedApiKey } })
      .then((response) => {
        if (response.status === 200) {
          const productDetails = response.data[0]; // Assuming the API response includes product details
          // Update state to set the product name and category
          setProducts([
            {
              productName: productDetails.Product_Name,
              categoryName: productDetails.Domain_Category_Name,
              productUrls: [''],
              channelNames: [''],
              productID: Product_ID
            },
          ]);
          
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  return (
    <>
      <div className="mt-5">
        <div className="container" style={{ border: '2px solid black', padding: '20px', borderRadius: '5px' }}>
          <h3 className="mb-3">Products Detail Entry</h3>
          <form onSubmit={handleProductsSubmit}>
            {products.map((product, index) => (
              <div key={index} className="row form-group" style={{ border: '2px dashed black', margin: '20px', padding: '20px', borderRadius: '5px' }}>
                <div className='col-md-6'>
                  <label htmlFor={`productName${index}`}><strong>Product Name:</strong></label>
                  <input
                    type="text"
                    className="form-control"
                    id={`productName${index}`}
                    value={product.productName}
                    onChange={(e) => handleProductInputChange(index, 'productName', 'x', e.target.value)}
                  />
                </div>
                <div className='col-md-6'>
                  <label htmlFor={`categoryName${index}`}><strong>Category Name:</strong></label>
                  <select
                    className="form-control"
                    id={`categoryName${index}`}
                    value={product.categoryName}
                    onChange={(e) => handleProductInputChange(index, 'categoryName', 'x', e.target.value)}
                  >
                    <option value="">Select Category Name</option>
                    {organizedCategories.map((category) => (
                      <optgroup label={category.Categoryname} key={category.Categoryid}>
                        {category.subcategories.map((subcategory) => (
                          <option
                            value={subcategory.SubCategoryid}
                            key={subcategory.SubCategoryid}
                          >
                            {subcategory.SubCategoryname}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                </div>
                <div className='col-md-12'>
                  {product.productUrls.map((url, urlIndex) => (
                    <div className="row" key={`productRow${index}-${urlIndex}`}>
                      <div className='col-md-6 mt-2'>
                        <label htmlFor={`productUrl${index}-${urlIndex}`}><strong>Product Url:</strong></label>
                        <input
                          type="text"
                          className="form-control"
                          id={`productUrl${index}-${urlIndex}`}
                          value={url}
                          // onChange={(e) => handleProductInputChange(index, 'productUrls', e.target.value)}
                          onChange={(e) => handleProductInputChange(index, 'productUrls', urlIndex, e.target.value)}
                        />
                      </div>
                      <div className='col-md-6 mt-2'>
                        <label htmlFor={`channelName${index}-${urlIndex}`}><strong>Channel Name:</strong></label>
                        <select
                          className="form-control"
                          id={`channelName${index}-${urlIndex}`}
                          value={product.channelNames[urlIndex]}
                          // onChange={(e) => handleProductInputChange(index, 'channelNames', e.target.value)}
                          onChange={(e) => handleProductInputChange(index, 'channelNames', urlIndex, e.target.value)}
                        >
                          <option value="">Select Channel Name</option>
                          {channels.map((channel) => (
                            <option value={channel.channelid} key={channel.channelid}>{channel.channelname}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-3">
                  <button type="button" className="btn btn-secondary ml-2" onClick={() => addChannelAndUrl(index, product.productUrls.length)}>PU&C</button>
                </div>
              </div>
            ))}
            <button type="button" className="btn btn-secondary mr-2" onClick={handleProductMoreButtonClick}>More</button>
            <button type="submit" className="btn btn-primary">Submit</button>
            {productsSuccessMessage && <span className="text-success ml-2">{productsSuccessMessage}</span>}
          </form>
        </div>
      </div>
      <div className='mt-5'>
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Product Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {productList.map((data, index) => {
              return (
                <tr key={data.Product_ID}>
                  <th scope="row">{index + 1}</th>
                  <td>{data.Product_Name}</td>
                  <td>
                    <Link
                      className="btn btn-primary"
                      onClick={() => handleAddNewChannelToExisting(data.Product_ID)}
                    >
                      Add Channel
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
