import Axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { hashedApiKey } from '../api'
import { API_BASE_URL } from '../api';

export default function BrandList({isAdmin, setIsAdmin}) {
    const [brandList, setBrandList] = useState([])
    const [grandTotalReviews, setGrandTotalReviews] = useState(0);

    useEffect(() => {
        const validateAdmin = async () => {
            try {
                const response = await Axios.get(API_BASE_URL + '/api/validate-admin', {
                    params: { isAdmin:isAdmin },
                    headers: { 'x-api-key': hashedApiKey }
                });
                
                if (!response.data.isValid){
                    console.log(response.data.isValid)
                    localStorage.setItem('admin', JSON.stringify(response.data.isValid));
                    setIsAdmin(JSON.stringify(response.data.isValid))

                    window.location.reload();   
                }
            } catch (error) {
                // Handle error if any
            }
        };

        validateAdmin();
    }, [isAdmin]);




    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getBrandList', {
            headers: {
                'x-api-key': hashedApiKey
            }
            
        }).then((response) => {
            setBrandList(response.data)
            setGrandTotalReviews(response.data[0].grand_total_reviews)
        })
    }, [])

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <div>
            <div className='row ml-auto'>
                <a className='btn btn-danger btn-sm mr-2' onClick={handleLogout}>Logout</a>
            </div>
            <table className="table">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Brand Name</th>
                        <th scope="col">Total Products</th>
                        <th scope="col">Total Reviews</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">Grand Total Reviews: <strong>{grandTotalReviews}</strong></th>
                </tr>
                {brandList.map((data, index) => {
                        return (
                            <tr key={data.Brand_ID}>
                                <th scope="row">{index + 1}</th>
                                <td>{data.Brand_Name}</td>
                                <td>{data.total_products}</td>
                                <td>{data.total_reviews}</td>
                                <td>
                                    <Link
                                        className="btn btn-primary"
                                        to={`/SignIn`}
                                        onClick={() => {
                                            localStorage.setItem('brand', JSON.stringify(data.Brand_ID));
                                        }}
                                    >
                                        Detail
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}
                    {/* {
                        brandList.map((data, index) => (
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{data.Brand_Name}</td>
                                <td>{data.total_products}</td>
                                <td>{data.total_reviews}</td>
                                <td><Link className='btn btn-primary' to={`/SignIn`} onClick={() => { localStorage.setItem('brand', JSON.stringify(data.Brand_ID)); }} >Detail</Link></td>
                            </tr>
                        ))
                    } */}

                </tbody>
            </table>
        </div>
    )
}

